import React, { useState, useEffect } from 'react'
import { DrizzleContext } from "drizzle-react";
import './style.scss';

//import Table from '../../components/Table2/Table';
import Table from '../../components/TableWaitlist/Table';
import Pagination from '../../components/pagination/pagination';

//import data
//import { data } from '../../data';

//import { newContextComponents } from "drizzle-react-components";
//const { ContractData } = newContextComponents;
import ContractData from '../../components/ContractData/ContractData';
//import ContractData from '../components/ContractData/ContractData';




const Waitlist = () => {
  const headerobj = [
      {name:"Entrant #"},
      {name:"Choice"},
      {name:"Player"}
  ]

  const [waitlistMoveCount, setWaitlistMoveCount] = useState(0);
  const [waitlistCount, setWaitlistCount] = useState(0);

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);

  const [initialItem, setInitialItem] = useState(0);


  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);


      //paginate forward
  const paginateForward = () => {

//    console.log(+pages)
//    console.log(+currentPage)
    if(+pages === +currentPage) {return null}
//      setPages(pages => pages + 1)
    setCurrentPage(+currentPage + 1)
    setWaitlistMoveCount(+waitlistMoveCount + 10)


    setInitialItem(+initialItem + 10)

    setEnd(end+10)
    setStart(start+10)

  }

  //paginate backward
  const paginateBackward = () => {
      if(currentPage === 1) {return null}
      setCurrentPage(+currentPage - 1)
      setWaitlistMoveCount(+waitlistMoveCount - 10)


      setInitialItem(+initialItem - 10)

      setEnd(end-10)
      setStart(start-10)

  }

  useEffect(() => {}, []);


  return (
    <DrizzleContext.Consumer>
      {drizzleContext => {
        const { drizzle, drizzleState, initialized } = drizzleContext;

        const { web3 } = drizzle

        if (!initialized) {
          return "Loading...";
        }

        if (!drizzleState.drizzleStatus.initialized) {
          return "Loading...";
        }

        let _waitlistMoveCount = drizzleState.contracts.Truel.waitingListMoveCount;
        let _waitlistCount = drizzleState.contracts.Truel.waitingListCount;
    //    let waitlistMoveCount = 0;


        if(_waitlistMoveCount['0x0'] !== undefined && waitlistMoveCount === 0) {
    //      waitlistMoveCount = +x['0x0'].value;
        //  console.log(x['0x0'].value)
      //    console.log( _waitlistMoveCount)
          const totalWaitlistMove = +_waitlistMoveCount['0x0'].value;
          setWaitlistMoveCount(totalWaitlistMove)



        }

        if(_waitlistCount['0x0'] !== undefined && waitlistCount === 0) {
    //      waitlistMoveCount = +x['0x0'].value;
        //  console.log(x['0x0'].value)
      //    console.log( _waitlistMoveCount)
          const totalWaitlist = +_waitlistCount['0x0'].value;
          setWaitlistCount(totalWaitlist)


          setInitialItem(totalWaitlist-10)
          setEnd(totalWaitlist)
          setStart(totalWaitlist-10)

          setCurrentPage((totalWaitlist/10).toFixed(0))
          setPages((totalWaitlist/10).toFixed(0))

        }

      //  console.log(waitlistCount + ' ' + waitlistMoveCount)


        const renderListCount = data => {

        //  console.log(data)

          if(data !== waitlistCount) {
            setWaitlistCount(data)

      //      setPages((data/10).toFixed(0))
          }



          return(
            <>
            {data}
            </>
          )

        };

        const renderMoveCount = data => {

//          console.log(data)
          const list = +waitlistCount - 10;
          setWaitlistMoveCount(data)

          return(
            <>
            {start}
            </>
          )

        };

        const renderNormal = () => {

          if(window.web3.currentProvider.isTrust) {
            return (
              <div className="waitlist">
                  <div className="rounds">
                  <p>
                    This page is not supported in Trust Wallet.
                  </p>
                  <p>
                    Coming Soon(tm)
                  </p>
                  </div>
              </div>
            )
          }

          return (
            <div className="waitlist">
                <div className="rounds">

                   <p>Waiting {' '}

                   <ContractData
                    drizzle={drizzle}
                    drizzleState={drizzleState}
                    contract="Truel"
                    method="waitingListMoveCount"
                    render={renderMoveCount}
                  />
                   /
                   <ContractData
                    drizzle={drizzle}
                    drizzleState={drizzleState}
                    contract="Truel"
                    method="waitingListCount"
                    render={renderListCount}
                   />
                   </p>

                   <p>
                   {'Waitlist Jail '}
                   {waitlistMoveCount}
                   {'+'}
                   </p>
                </div>

              <Table type="waitlist"
              start={start}
              end={end}
              locked={waitlistMoveCount}
              header={headerobj}/>
               <Pagination
                currentPage={currentPage}
                totalPages={pages}
                paginateFront={paginateForward}
                paginateBack={paginateBackward} />
            </div>
          )
        }


        return (
          renderNormal()
        );
      }}
    </DrizzleContext.Consumer>
  );
};

export default Waitlist;

/*
const Waitlist = () => {
    const headerobj = [
        {name:"Entrant #"},
        {name:"Choice"},
        {name:"Player"}
    ]
    const [page,setPage] = useState(1);
    const dataObj = data(page);

        //paginate forward
        const paginateForward = () => {
            setPage(prevPage => prevPage + 1)
        }

        //paginate backward
        const paginateBackward = () => {
            setPage(prevPage => prevPage - 1)
        }
    return (
        <div className="waitlist">
            <div className="rounds">
               <p>Waiting 530/900</p>
            </div>

            <Table type="waitlist" data={dataObj.waitingList.data} header={headerobj}/>
           <Pagination
            currentPage={dataObj.waitingList.current_page}
            totalRecords={dataObj.waitingList.totalRecords}
            paginateFront={paginateForward}
            paginateBack={paginateBackward} />
        </div>
    )
}

export default Waitlist;
*/
