import React from 'react';

import { Pacman } from 'react-pure-loaders';
import LogoGray from '../../assets/grayscale-transparent.png';
import Coinbase from '../../assets/external/coinbase.png';
import Trust from '../../assets/external/trust.svg';
import Metamask from '../../assets/external/metamask-fox.svg';
import Brave from '../../assets/external/brave.svg';
import DrizzleLogo from '../../assets/external/drizzle.png';
import './style.scss';


const Loader = ({}) => {


    return (
      <div className="loader2">
        <div className="loaderContent2">
          <h1>Play more. Need more data. </h1>
          <div className="pac">
          <Pacman
            color={"#bfa706"}
            loading={true}/>
            </div>
        </div>
      </div>
    )

}

export default Loader;
