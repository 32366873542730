import React from 'react';
import './style.scss';

//import rulecard
import RuleCard from '../../components/ruleCard/ruleCard';

//import Icons
import IconHTP from '../../assets/icons/icon-howtoplay.svg';
import IconJackpot from '../../assets/icons/icon-jackpot.svg';
import IconWaitlist from '../../assets/icons/icon-waitlist2.svg';
import IconRounds from '../../assets/icons/icon-rounds.svg';
import IconSplits from '../../assets/icons/icon-splits.svg';
import IconReward from '../../assets/icons/icon-reward.svg';

const Rules = () => {
    return (
        <div className="rules">
           <h1 className="header-title">Rules</h1>
           <RuleCard title="How To Play" icon={IconHTP} linkTo="details"/>
           <RuleCard title="Jackpots" icon={IconJackpot} linkTo="jackpots"/>
           <RuleCard title="Waitlist" icon={IconWaitlist} linkTo="waitlist"/>
           <RuleCard title="Rounds" icon={IconRounds} linkTo="round"/>
           <RuleCard title="Fees" icon={IconSplits} linkTo="fee"/>
           <RuleCard title="Rewards" icon={IconReward} linkTo="reward"/>
        </div>
    )
}

export default Rules;
