import React from 'react';
import { DrizzleContext } from "drizzle-react";
//import { DrizzleContext } from "@drizzle/react-plugin";
//import { newContextComponents } from "drizzle-react-components";


import './style.scss';
import PlayCard from '../../components/playCards/playCards';
import ScoreCard from '../../components/scoreCards/scoreCards';
import Particles from '../../components/headerParticle/headerParticle';

//import icons
import GunLeft from '../../assets/icons/icon-gun-left.svg';
import Bullet from '../../assets/icons/icon-bullet.svg';
import GunRight from '../../assets/icons/icon-gun-right.svg';
import GrandIcon from '../../assets/icons/icon-grand.svg';
import EtheriumIcon from '../../assets/icons/icon-etherium.svg';
import TokenIcon from '../../assets/icons/icon-token.svg';
//const { ContractData } = newContextComponents;
import { newContextComponents } from "drizzle-react-components";

//import "react-toastify/dist/ReactToastify.css";
//import './components/style.css';

const { ContractData } = newContextComponents;


export default () => (
  <DrizzleContext.Consumer>
    {drizzleContext => {
      const { drizzle, drizzleState, initialized } = drizzleContext;


      if (!initialized) {
        return "Loading...";
      }

      if (!drizzleState.drizzleStatus.initialized) {
        return "Loading...";
      }

      //const BACKFIRE_JACKPOT = drizzleState.contracts.Truel.BACKFIRE_JACKPOT['0x0'].value;
  //    const gasPrice = await drizzle.web3.eth.getGasPrice()
//      console.log(drizzle.web3.eth.getGasPrice)
      drizzle.web3.eth.getGasPrice(function(error, result){
    //      console.log(result);
      });


      const leftGunClick = () => {

        const contract = drizzle.contracts.Truel;
        const dataKey = contract.methods["DENOMINATION"].cacheCall();

        const { Truel } = drizzleState.contracts;
        const methVal = Truel.DENOMINATION[dataKey];
        //20000000000

//        drizzle.contracts.Truel.methods.enterTruel.cacheSend(0,{value: methVal.value, gasPrice: 2000000000})
      //  drizzle.contracts.Truel.methods.enterTruel.cacheSend(0,{value: methVal.value })

        drizzle.web3.eth.getGasPrice(function(error, result){
      //      console.log(result);
            drizzle.contracts.Truel.methods.enterTruel.cacheSend(0,{value: methVal.value })
        });

      }

      const missClick = () => {

        const contract = drizzle.contracts.Truel;
        const dataKey = contract.methods["DENOMINATION"].cacheCall();

        const { Truel } = drizzleState.contracts;
        const methVal = Truel.DENOMINATION[dataKey];

        //web3.eth.getGasPrice(function(e, r) { console.log(r) })
        //drizzle.contracts.Truel.methods.enterTruel.cacheSend(1,{value: methVal.value })

//        drizzle.web3.eth.getGasPrice(function(error, result){
//              drizzle.contracts.Truel.methods.enterTruel.cacheSend(1)
//        });
        drizzle.contracts.Truel.methods.enterTruel.cacheSend(1,{value: methVal.value })
      }

      const righGunClick = () => {

        const contract = drizzle.contracts.Truel;
        const dataKey = contract.methods["DENOMINATION"].cacheCall();

        const { Truel } = drizzleState.contracts;
        const methVal = Truel.DENOMINATION[dataKey];

    //    drizzle.contracts.Truel.methods.enterTruel.cacheSend(2,{value: methVal.value, gasPrice: 2000000000})
        //drizzle.contracts.Truel.methods.enterTruel.cacheSend(2,{value: methVal.value })
        drizzle.web3.eth.getGasPrice(function(error, result){
      //      console.log(result);
            drizzle.contracts.Truel.methods.enterTruel.cacheSend(2,{value: methVal.value })
        });
      }
      //      <Particles/>
      return (
        <div className="play">

          <ContractData
            drizzle={drizzle}
            drizzleState={drizzleState}
            contract="Truel"
            method="DIVIDENDS_JACKPOT"
            render={data =>  <ScoreCard logo={EtheriumIcon} image={GrandIcon} number={retbal(data)} />}
          />

          <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>

          <ContractData
            drizzle={drizzle}
            drizzleState={drizzleState}
            contract="Truel"
            method="MINI_JACKPOT"
            render={data =>      <ScoreCard key={1} logo={EtheriumIcon} number={retbal(data)} title="MAJOR"/>}
          />


          <ContractData
            drizzle={drizzle}
            drizzleState={drizzleState}
            contract="Truel"
            method="BACKFIRE_JACKPOT"
            render={data =>  <ScoreCard key={2} logo={TokenIcon} number={retbal(data)} title="MINOR"/>}
          />

          </div>
          <div className="header-box">
          <h1 className="header-title">Play</h1></div>
          <div className="play-btns">
          <PlayCard text="Left" icon={GunLeft} click={leftGunClick}/>
          <PlayCard text="Miss" icon={Bullet} click={missClick}/>
          <PlayCard text="Right" icon={GunRight} click={righGunClick}/>
          </div>
        </div>
      );
    }}
  </DrizzleContext.Consumer>
);


const countDecimals = function(value) {

    return value.toString().split(".")[0];
}

const retbal = data => {
  const toEther = +window.web3._extend.utils.fromWei(data, 'ether');

  if(countDecimals(toEther) > 2) {
    let toEtherFixed = toEther.toFixed(2)
    return toEtherFixed
  }

  if(countDecimals(toEther) > 3) {
    let toEtherFixed = toEther.toFixed(1)
    return toEtherFixed
  }

  let toEtherFixed = toEther.toFixed(3)

  //const SVG = () =>
  //<svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Ethereum icon</title><path d="M11.944 17.97L4.58 13.62 11.943 24l7.37-10.38-7.372 4.35h.003zM12.056 0L4.69 12.223l7.365 4.354 7.365-4.35L12.056 0z"/></svg>;

  return toEtherFixed
};

/*
<ScoreCard key={1} logo={EtheriumIcon} number="10.50" title="MAJOR"/>
<ScoreCard key={2} logo={TokenIcon} number="0.900" title="MINOR"/>
*/
