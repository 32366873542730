import React from 'react';
import './style.scss';
import IconArrowBack from '../../assets/icons/icon-arrowback.svg';
import IconRounds from '../../assets/icons/icon-rounds.svg';
import { Link } from 'react-router-dom';

const RulesDetail = () => {
    return (
        <div className="rules-detail">
          <Nav header="Rounds" icon={IconRounds}/>
          <div className="text-body">
          <ol>
          <li>Last few players in the waitlist cannot enter into a round</li>
          <li>Players must maintain a p3x balance in the contract to create rounds; see rewards</li>
          <li>Players stake a portion of contract p3x to create round</li>
          <li>Rounds that dont finalize in time, declare all players dead!!!</li>
          <li>...and lock all round staked p3x(including round creator)</li>
          </ol>
          </div>
        </div>
    )
}

export default RulesDetail;

const Nav = ({header,icon}) => {
    return (
        <div className="detail-nav">
            <div className="item1">
            <Link to="/rules"><img src={IconArrowBack} alt="back"/></Link>
            <h1 className="header-title" style={{marginBottom:0}}>{header}</h1>
            </div>
            <div>
            <img src={icon} alt={`icon-${header}`}/>
            </div>
        </div>
    );
}
