import React, { Component } from 'react';
import { Drizzle } from 'drizzle';
import { DrizzleContext } from "drizzle-react";
//import axios from 'axios';
//import './App.css';
import {
  LoadingContainer
} from "drizzle-react-components";
import Dapp from './Dapp';
import drizzleOptions from "./drizzleOptions";
import Names from './contracts/Names.json'
import Truel from './contracts/Truel.json'
//const drizzleStore = generateStore(drizzleOptions);

import store from './middleware'
//import data from './middleware/players.json';

import { getName } from './middleware'
import { getWeb3 } from './middleware'
const drizzle = new Drizzle(drizzleOptions, store);

const web3 = getWeb3();

const ropstenContractAddress = '0x5bdd5745e2dbe04d16a0e9d2fd0607a8f7bf09f4';
const mainContractAddress = '0x7e002c7c2b0c2e821ea68d56489bd18c8cd79ab1';

const ropsten3DuelContractAddress = '0x128A199D9F53B59e4a9f13Bfd1c6D6486897a9d0';
const main3DuelContractAddress = '0xD11851c8Ba1dcEB72207aCB63fAa93253D57Cc00';

const insulter = require('insult');



class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      checkedItems: new Set()
    }

  }

  componentDidMount() {


    if(web3 === null) {
      console.log('...')
    }
    else {
      this.installWatchers()
      this.annoyingLeaderSpam()

    //  setTimeout(() => {this.getPastGameData();}, 10000);
    }

  }

  getPastGameData() {
    this.triggerInviteMessage()
    this.triggerRewardsMessage()
    this.installWatchersGameTips()
    this.installWatchers2();
    this.installWatchers3();
  }

  addItem(item) {
    this.setState(({ checkedItems }) => ({
      checkedItems: new Set(checkedItems).add(item)
    }));
  }

  getItemCheckedStatus(item) {

    return this.state.checkedItems.has(item)
  }

  triggerInviteMessage() {
      setInterval(() => {
        //console.log(this.props)
        drizzle.store.dispatch({type: 'ADD_MESSAGE', text: 'Seek comfort with other fallen players', id: Date.now(), stage: 'JOIN'})
      }, 300000);
  }

  annoyingLeaderSpam() {


    setInterval(() => {
      //console.log(this.props)
//      console.log(drizzle.store.getState().nameReducer.leader.name)
      const leaderName = drizzle.store.getState().nameReducer.leader.name
      const insult = insulter.Insult().toLowerCase();
      const message = `[LEADER] ${leaderName} ${insult}`
    //  console.log(message)
//        store.dispatch({type: 'ADD_MESSAGE_FRONT', text: messageLeaderChange, id: Date.now()+10000, stage: 'XXXX'})
      drizzle.store.dispatch({type: 'ADD_MESSAGE_FRONT', text: message, id: Date.now(), stage: 'XXXX'})
    }, 1200000);
  }


  triggerRewardsMessage() {

    // /              store.dispatch({type: 'ADD_MESSAGE', text: message, id: Date.now(), stage: 'WAITLIST_ENTERED'})

    //  if(drizzle.store.getState().contracts.Truel)
      //.contracts.Truel.validatorBagMin['0x0'].value
//      http://localhost:3000/rewards
//  const toEther = drizzle.web3.extend.utils.fromWei(data, 'ether');

      setInterval(() => {
      //console.log(this.props)
    //  console.log(drizzle.store.getState().contracts.Truel)

      if(drizzle.store.getState().drizzleStatus.initialized === false) {
        drizzle.store.dispatch({type: 'ADD_MESSAGE', text: 'You need web3', id: Date.now(), stage: 'INSTRUCTION'})
        return
      }

      const bagmin = drizzle.store.getState().contracts.Truel.validatorBagMin
      const p3xbal = drizzle.store.getState().contracts.Truel.getBalance
      const cancreaternd = drizzle.store.getState().contracts.Truel.canCreateRound
    //  console.log(drizzle.store.getState())
      //console.log(drizzle.store.getState().initialized)
      //console.log(drizzle.store.getState().drizzleStatus.initialized)
  //    console.log(p3xbal)
  //    console.log(bagmin)
      //['0x0'].value
      if(cancreaternd['0x0'].value === true && (p3xbal['0x0'].value > bagmin['0x0'].value)) {
        drizzle.store.dispatch({type: 'ADD_MESSAGE', text: 'A baller like you can create rounds', id: Date.now(), stage: 'REWARDS'})
      }
      else if(p3xbal['0x0'].value > bagmin['0x0'].value) {
        drizzle.store.dispatch({type: 'ADD_MESSAGE', text: 'Get more people to play, so you have a better chance at creating rounds', id: Date.now(), stage: 'MARKET'})
      }
      else {
        drizzle.store.dispatch({type: 'ADD_MESSAGE', text: 'You need 10 p3x balance to create rounds. Shoot more...', id: Date.now(), stage: 'TAUNT'})
//        console.log('10 p3x balance to finalize round you')
      }

      //  drizzle.store.dispatch({type: 'ADD_MESSAGE', text: 'Join the discord channel....', id: Date.now(), stage: 'JOIN'})
    }, 200000);

  }

/*
http://localhost:3000/rewards
*/

  installWatchersGameTips() {
    //console.log(this.props)
    drizzle.store.dispatch({type: 'ADD_MESSAGE', text: 'Discuss your violent end in Discord', id: Date.now(), stage: 'JOIN'})
    drizzle.store.dispatch({type: 'ADD_MESSAGE', text: 'Protip: surviving the round is critical', id: Date.now(), stage: 'INFO'})
    drizzle.store.dispatch({type: 'ADD_MESSAGE', text: 'Jackpots: Are Cool - Read about them in Rules Section', id: Date.now(), stage: 'INFO_JACKPOTS'})
    drizzle.store.dispatch({type: 'ADD_MESSAGE', text: 'Round Creator: Stakes P3X to create rounds, first option to finalize round', id: Date.now(), stage: 'INFO'})
    drizzle.store.dispatch({type: 'ADD_MESSAGE', text: 'Round Finalizer: Can win the Minor Jackpot', id: Date.now(), stage: 'INFO'})
    drizzle.store.dispatch({type: 'ADD_MESSAGE', text: 'Last Buyer = Chance at Major & Grand Jackpot', id: Date.now(), stage: 'INFO'})

  }



  installWatchers3() {
  //  console.log(web3)
    const namesContract = new web3.eth.Contract(Truel.abi, (web3._provider.networkVersion === 1) ? main3DuelContractAddress : ropsten3DuelContractAddress );

    namesContract.getPastEvents('allEvents',{ fromBlock: 0, toBlock: 'latest' },(error, result) => {
        if (error) { console.log('error event ' + error) }
        if (!error) {
          var obj=JSON.parse(JSON.stringify(result));

          if(obj.length < 50) {return}

          const length = obj.length
          const startLength = obj.length-5

//          console.log(result)
          for(let x = startLength;x < length;x++) {

          //  const nameString = web3.utils.toAscii(obj[x].returnValues.player).replace(/[^\x20-\x7E]/g, '')
          //  const playerAddress = obj[x].returnValues.player

            let type = obj[x].event

            if(type  === 'RoundEntered') {
              const playerAddress = obj[x].returnValues.player;
              let player = getName(drizzle.store.getState().nameReducer, playerAddress);
            //  let player = action.event.returnValues.player;
            //  if(store.getState().accounts[0] === player) {player = 'you'}

              const message = `#${obj[x].blockNumber} round created by ${player}`
              store.dispatch({type: 'ADD_MESSAGE', text: message, id: Date.now(), stage:'ROUND_ENTERED' })

      //        console.log(message)
            }

            if(type  === 'WaitlistEntered') {
              const playerAddress = obj[x].returnValues.player;
              let player = getName(drizzle.store.getState().nameReducer, playerAddress);

              if(store.getState().accounts[0] === playerAddress) {
                const message = `Congrats. You grew a pair. Welcome to hell you %#s!&!`;

                store.dispatch({type: 'ADD_MESSAGE', text: message, id: Date.now(), stage: 'WAITLIST_ENTERED'})
              }


              const message = `#${obj[x].blockNumber} ${player} entered the Fray`;
              store.dispatch({type: 'ADD_MESSAGE', text: message, id: Date.now(), stage: 'WAITLIST_ENTERED'})
              store.dispatch({type: 'LEADER_CHANGE', id: Date.now(), address: playerAddress, name: player, stage:'LEADER_CHANGE' })
        //      console.log(message)
            }

            if(type  === 'PlayerShot') {
              const shooterAddress = obj[x].returnValues.shooter
              const deadAddress = obj[x].returnValues.dead
              let dead = getName(drizzle.store.getState().nameReducer, deadAddress);
              let shooter = getName(drizzle.store.getState().nameReducer, shooterAddress)

              //console.log(store.getState().accounts[0])
               if(store.getState().accounts[0] === shooterAddress) {
                 const message = `ROFLLLLLLLL - You finally killed someone`
                 store.dispatch({type: 'ADD_MESSAGE', text: message, id: Date.now(), stage: 'PLAYER_SHOT'})
               }

               if(store.getState().accounts[0] === deadAddress) {
                 const message = `3DUEL GODS - "We are actually glad you died. TBH... you suck"`
                 store.dispatch({type: 'ADD_MESSAGE', text: message, id: Date.now(), stage: 'PLAYER_SHOT'})
               }

              const message = `#${obj[x].returnValues.roundNumber} ${shooter} shot ${dead}`
              store.dispatch({type: 'ADD_MESSAGE', text: message, id: Date.now(), stage: 'PLAYER_SHOT'})
          //    console.log(message)
            }



          }



        }

    });
  }


  installWatchers2() {
    //console.log(this.props)
    const namesContract = new web3.eth.Contract(Names.abi, (web3._provider.networkVersion === 1) ? mainContractAddress : ropstenContractAddress );
//    console.log('getting logs')
    namesContract.getPastEvents('OnNewName',{ fromBlock: 0, toBlock: 'latest' },(error, result) => {
        if (error) { console.log('error event ' + error) }
        if (!error) {
          var obj=JSON.parse(JSON.stringify(result));


          const length = obj.length
          const startLength = obj.length-5
          if(length<40) {return}

          for(let x = startLength;x < length;x++) {
        //    console.log(obj[x].returnValues)
            const nameString = web3.utils.toAscii(obj[x].returnValues.playerName).replace(/[^\x20-\x7E]/g, '')
            const playerAddress = obj[x].returnValues.nameCreator.substring(0,11)
            drizzle.store.dispatch({type: 'ADD_NAME', name: nameString, id: obj[x].returnValues.nameCreator})
        //    const nameCreator = result.returnValues.nameCreator.substring(0,9)

            const message = `${playerAddress} will now be known as ${nameString}`
            drizzle.store.dispatch({type: 'ADD_MESSAGE', text: message, id: Date.now(), stage: 'NEW_NAME'})

          }

        }

    });
  }


  installWatchers() {
    //console.log(this.props)
    const namesContract = new web3.eth.Contract(Names.abi, (web3._provider.networkVersion === 1) ? mainContractAddress : ropstenContractAddress );
//    console.log(namesContract)
    namesContract.events.OnNewName((error, result) => {
        if (error) { console.log('error event ' + error) }
        if (!error) {

          if(this.getItemCheckedStatus(result.id) === false) {
            this.addItem(result.id)

            const nameString = web3.utils.toAscii(result.returnValues.playerName).replace(/[^\x20-\x7E]/g, '')
            const playerAddress = result.returnValues.nameCreator
            const nameCreator = result.returnValues.nameCreator.substring(0,9)

            drizzle.store.dispatch({type: 'ADD_NAME', name: nameString, id: playerAddress})


            if(store.getState().accounts[0] === playerAddress) {
              const message = `${nameCreator} (YOU) will now be known as ${nameString}`
              drizzle.store.dispatch({type: 'ADD_MESSAGE', text: message, id: Date.now(), stage: 'NEW_NAME'})
            }
            else {
              const message = `${nameCreator} will now be known as ${nameString}`
              drizzle.store.dispatch({type: 'ADD_MESSAGE', text: message, id: Date.now(), stage: 'NEW_NAME'})
            }

          }

        }

    });

  }



  render() {
//    console.log(window.location.href)
  //  console.log(this.state)
//
    return (
      <DrizzleContext.Provider drizzle={drizzle}>
        <Dapp />
      </DrizzleContext.Provider>
    );
  }
}

export default App;
