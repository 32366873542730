import React from 'react';
import './style.scss';
const balanceCard = ({icon,price,title}) => {
    return (
        <div className="balance-card">
            <div>
            <img src={icon} alt="balance-card-icon"/>
            <p>{title}</p>
            </div>
            <h1 className="price">{price}</h1>
            
        </div>
    )
}

export default balanceCard;
