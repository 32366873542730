import { fork } from 'redux-saga/effects';

import { fetchRound } from './RoundSagas.js';


export function* rootSaga() {
  yield [
    fork(fetchRound)
//    fork(watchLoginSuccess),
//    fork(watchLoginFailure),
//    fork(watchLogout),
//    fork(watchApolloQuery),
//    fork(watchApolloQueryResultClient),
//    fork(watchApolloMutationResult),
//    fork(watchAdManager),
//    fork(watchEnableAdCommunicationChannel),
//    fork(watchResetAdCount),
//    fork(watchSendNewAd),
//    fork(watchSetPreviousAdUnit),
//    fork(watchDeleteRunlistItem),
//    fork(watchAddErrorListItem),
//    fork(watchDisableAdCommunicationChannel),
//    fork(watchLogAdRun),
//    fork(watchSettings),
//    fork(checkAdQueue),
//    fork(watchSetCurrentAdUnit),
//    fork(watchChatThisAd),
//    fork(watchCreateChatClient)
  ];
}
