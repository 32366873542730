import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
//import { newContextComponents } from "drizzle-react-components";
import { DrizzleContext } from "drizzle-react";

import ContractData from './components/ContractData/ContractData';
//import LoadingScreen from 'react-loading-screen';
import Loader from './components/loader/loader'
import Home from './pages/home/home';
//import { abi as namesABI } from "./contracts/Names.json"
//const { ContractData } = newContextComponents;

function ropstencheck(url) {
  if(url === "http://192.168.1.251:3000") return true
  if(url === "http://localhost:3000") return true
  if(url === "https://ropsten.3duel.com") return true
  return false
}

function maincheck(url) {
  //console.log(url)
  if(url === "http://192.168.1.251:3000") return true
  if(url === "http://localhost:3000") return true
  if(url === "https://3duel.com") return true
  return false
}





const Dapp = () => {
  const [time, setTime] = useState(new Date().getTime());
  const [time2, setTime2] = useState(new Date().getTime());

  useEffect(() => {
//      console.log(time)
      const id = setTimeout(() => setTime2(new Date().getTime()), 5000);
      return () => {
          clearInterval(id);
      }
  }, []);

//  const handleChange = event => setTime(event.target.value);


  return (
    <DrizzleContext.Consumer>
      {drizzleContext => {
        const { drizzle, drizzleState, initialized } = drizzleContext;

        const { web3 } = drizzle
        const selectedAccount = drizzleState ? drizzleState.accounts[0] : '0x0'
        const userDeniedAccess = drizzleState && drizzleState.web3.status === 'UserDeniedAccess'




        if (typeof web3 === 'undefined') {
          return (
            <div>
             <Loader time={1} time2={100} />
            </div>
        )}


        if (!initialized) {
          return (
            <div>
             <Loader time={time} time2={time2} />
           </div>
          );
        }


        if (userDeniedAccess) {
          return (
            <div>
              <Loader text={'You need to approve access to Play 3Duel'} />
            </div>
        )}

        if (!drizzleState.drizzleStatus.initialized) {
          return "Loading...";
        }

        if(drizzleState.web3.networkId === 3 && !ropstencheck(window.location.origin)) {
          return <div>wrong network - goto <a target="_blank" rel="noopener noreferrer" href="https://ropsten.3duel.com">https://ropsten.3duel.com</a></div>
        }

        if(drizzleState.web3.networkId === 1 && !maincheck(window.location.origin)) {

          if(window.location.origin === "https://ropsten.3duel.com") {
            return <div>wrong network - switch to ropsten in metamask</div>
          }
            return <div>wrong network for mainnet contract</div>
        }


        const renderm = () => {

            return (
              <div>
              <ContractData
                drizzle={drizzle}
                drizzleState={drizzleState}
                contract="Truel"
                method="DIVIDENDS_JACKPOT"
                render={data => <div></div>}
              />

              <ContractData
                drizzle={drizzle}
                drizzleState={drizzleState}
                contract="Truel"
                method="MINI_JACKPOT"
                render={data => <div></div>}
              />


              <ContractData
                drizzle={drizzle}
                drizzleState={drizzleState}
                contract="Truel"
                method="DENOMINATION"
                render={data => <div></div>}
              />

              <ContractData
                drizzle={drizzle}
                drizzleState={drizzleState}
                contract="Truel"
                method="owner"
                render={data => <div></div>}
          //              render={renderInitSection}
              />

              <ContractData
               drizzle={drizzle}
               drizzleState={drizzleState}
               contract="Truel"
               method="isValidator"
               render={data => <div></div>}
          //       render={renderValidator}
             />
             <ContractData
               drizzle={drizzle}
               drizzleState={drizzleState}
               contract="Truel"
               method="getBalance"
               render={data => <div></div>}
             />
             <ContractData
              drizzle={drizzle}
              drizzleState={drizzleState}
              contract="Truel"
              method="validatorBagMin"
              render={data => <div></div>}
            />
            <ContractData
             drizzle={drizzle}
             drizzleState={drizzleState}
             contract="Truel"
             method="canCreateRound"
             render={data => <div></div>}
           />

              </div>
            )
        }



        return (
          <div className="App">

              {renderm()}

              <Router>
                  <Switch>
                      <Route path="/" component={Home} />
                  </Switch>
              </Router>

          </div >
        );
      }}
    </DrizzleContext.Consumer>
  );
};

export default Dapp;


/*
export default () => (
  <DrizzleContext.Consumer>
    {drizzleContext => {
      const { drizzle, drizzleState, initialized } = drizzleContext;

      if (!initialized) {
        return (
          <div>
           <Loader />
         </div>
        );
      }

      if (!drizzleState.drizzleStatus.initialized) {
        return "Loading...";
      }


      if(drizzleState.web3.networkId === 3 && !ropstencheck(window.location.origin)) {
        return <div>wrong network - goto <a target="_blank" rel="noopener noreferrer" href="https://ropsten.3duel.com">https://ropsten.3duel.com</a></div>
      }

      if(drizzleState.web3.networkId === 1 && !maincheck(window.location.origin)) {
        if(window.location.origin === "https://ropsten.3duel.com") {
          return <div>wrong network - switch to ropsten in metamask</div>
        }
          return <div>wrong network for mainnet contract</div>
      }


      return (
        <div className="App">

            <ContractData
              drizzle={drizzle}
              drizzleState={drizzleState}
              contract="Truel"
              method="DIVIDENDS_JACKPOT"
              render={data => <div></div>}
            />


            <ContractData
              drizzle={drizzle}
              drizzleState={drizzleState}
              contract="Truel"
              method="MINI_JACKPOT"
              render={data => <div></div>}
            />


            <ContractData
              drizzle={drizzle}
              drizzleState={drizzleState}
              contract="Truel"
              method="DENOMINATION"
              render={data => <div></div>}
            />

            <ContractData
              drizzle={drizzle}
              drizzleState={drizzleState}
              contract="Truel"
              method="owner"
              render={data => <div></div>}
//              render={renderInitSection}
            />

            <ContractData
             drizzle={drizzle}
             drizzleState={drizzleState}
             contract="Truel"
             method="isValidator"
             render={data => <div></div>}
      //       render={renderValidator}
           />
           <ContractData
             drizzle={drizzle}
             drizzleState={drizzleState}
             contract="Truel"
             method="getBalance"
             render={data => <div></div>}
           />
           <ContractData
            drizzle={drizzle}
            drizzleState={drizzleState}
            contract="Truel"
            method="validatorBagMin"
            render={data => <div></div>}
          />
          <ContractData
           drizzle={drizzle}
           drizzleState={drizzleState}
           contract="Truel"
           method="canCreateRound"
           render={data => <div></div>}
         />

            <Router>
                <Switch>
                    <Route path="/" component={Home} />
                </Switch>
            </Router>

        </div >
      );
    }}
  </DrizzleContext.Consumer>
);
*/
