import React from 'react';
import { MobileView,BrowserView } from 'react-device-detect';
import { BallBeat } from 'react-pure-loaders';
import LogoGray from '../../assets/grayscale-transparent.png';
import Coinbase from '../../assets/external/coinbase.png';
import Trust from '../../assets/external/trust.svg';
import Metamask from '../../assets/external/metamask-fox.svg';
import Brave from '../../assets/external/brave.svg';
import DrizzleLogo from '../../assets/external/drizzle.png';
import './style.scss';


const Loader = ({time, time2, text}) => {




    if(time2 > (time + 10)) {

      return (

        <div className="loader">
            <div className="loaderContent">
              <h1>3Duel Requires Web3 (options below)</h1>
              <h2>1. Login to web3</h2>
              <h2>2. Approve site access</h2>
              <h2>3. Or.... Download Wallet Provider</h2>
              <BallBeat color={"#bfa706"} loading={true}/>
              <h3>3Duel Verified Wallets</h3>
                <div className="recom">
                <h4>Recommended Desktop</h4>

                <a href="https://brave.com/due948"
                target="_blank" rel="noopener noreferrer">
                <img
                  height={'100'}
                  width={'200'}
                  src={Brave} alt="brave logo" />

                </a>

                </div>


                <div className="recom">
                <h4>Recommended Mobile</h4>
                  <a href="https://metamask.io"
                  target="_blank" rel="noopener noreferrer">
                    <img
                      height={'75'}
                      width={'75'}
                      src={Metamask} alt="metamask logo" />
                  </a>
                </div>

                <p>
                  <a href="https://share.trustwallet.com/P2lcZKw"
                  target="_blank" rel="noopener noreferrer">
                    <img
                      height={'100'}
                      width={'200'}
                      src={Trust} alt="3duel logo" />
                  </a>
                </p>
                <p>
                <a href="https://wallet.coinbase.com/"
                target="_blank" rel="noopener noreferrer">
                <img
                  height={'100'}
                  width={'200'}
                  src={Coinbase} alt="coinbase logo" />
                </a>
                </p>


              <img
                height={'300'}
                width={'300'}
                src={LogoGray} alt="trust logo" />
            </div>
        </div>

      )
    }


    return (
      <div className="loader">
        <div className="loaderContent">
          <h1>Loading 3Duel Experience</h1>
          <h5>Built with {'  '}
          <a href="https://www.trufflesuite.com/drizzle"
          target="_blank" rel="noopener noreferrer">
            <img
            height={'25'}
            width={'25'}
            src={DrizzleLogo}
            alt="drizzle logo"  />
            </a>
            </h5>
          <BallBeat color={"#bfa706"} loading={true}/>
          <h3>Thank you for your patience!</h3>
          <img src={LogoGray}
          height={'300'}
          width={'300'}
          alt="3duel logo" />
        </div>
      </div>
    )

}

export default Loader;
