import React from 'react';
import Blockies from 'react-blockies';
import IconLockedReward from '../../assets/icons/icon-lockedreward.svg';
import TimerIcon from '../../assets/icons/icon-waitlist.svg';
import './style.scss';

const roundCard = ({img,text,address,locked}) => {
    if(locked === true){
        return (
            <div className="round-card-with-image">
                <div className="round-card-no-image-alive">

                <img
                src={TimerIcon}
                alt="reward-card-icon"/>
                {text}
                </div>
            </div>
        )
    }
    else if(img && text === 'Alive'){
        return (
            <div className="round-card-with-image">


                <div className="round-card-no-image-alive">
                    <p>{address} - {text}</p>
                </div>
            </div>
        )
    }else if(img && text !== 'Alive'){
      return (
          <div className="round-card-with-image">


              <div className="round-card-no-image">
                  <p>{address} - {text}</p>
              </div>
          </div>
      )
    }
    else if(text.includes('(YOU)')){
        return (
          <div className="round-card-you">
              <p>{text}</p>
          </div>
        )
    }else{
        return (
            <div className="round-card-no-image">
                <p>{text}</p>
            </div>
        )
    }

}

export default roundCard;


/*
    <Blockies
    seed={address}
    color='#dfe'
    bgcolor='#aaa'
    />
  */
/*
const roundCard = ({img,text}) => {
    if(img && text){
        return (
            <div className="round-card-with-image">
                <img src={img} alt="player"/>
                <div className="round-card-no-image">
                    <p>{text}</p>
                </div>
            </div>
        )
    }else if(!text){
        return <img src={img} alt="player"/>
    }else{
        return (
            <div className="round-card-no-image">
                <p>{text}</p>
            </div>
        )
    }

}

export default roundCard;
*/
