import React from 'react';
import './style.scss';

const ScoreCards = ({title,image,number,logo}) => {
    if(!title){
        return (
            <>
            <div className="grand-box"><img src={image} alt="grand-logo"/><div>hello</div></div>
            <div className="score-cards">
            <div className="number-card">
                <Inner logo={logo}/>
                {number.split('').map((number,index) => <Inner key={index} number={number}/>)}
            </div>
            </div>
            </>
        )
    }else{
        return (
            <div className="score-cards-with-title">
                <div className="title">{title}</div>
                <div className="number-card">
                <Inner logo={logo}/>
                {number.split('').map((number,index) => <Inner key={index} number={number}/>)}
                </div>
            </div>
        )
    }

}

export default ScoreCards;

const Inner = ({number,logo}) => {
    if(logo) return  <div className="inner logo"><img src={logo} alt="logo"/></div>
    return <div className="inner"><h1>{number}</h1></div>
}
