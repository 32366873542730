import React from 'react';
import './style.scss';
import IconArrowBack from '../../assets/icons/icon-arrowback.svg';
import IconJackpot from '../../assets/icons/icon-jackpot.svg';
import { Link } from 'react-router-dom';

const RulesDetail = () => {
    return (
        <div className="rules-detail">
          <Nav header="Jackpots" icon={IconJackpot}/>
          <div className="text-body">
            <h4>Grand Jackpot</h4>

            <ol>
            <li>Funded by 3% referral</li>
            <li>All round entrants must survive to be eligible</li>
            <li>Rewards round entrants + last player in waitinglist</li>
            <li>Payout Ethereum = Grand Jackpot/4</li>
            </ol>

            <h4>Major Jackpot</h4>
            <ol>
            <li>Funded by contract dividends</li>
            <li>Sent to last player in waitinglist</li>
            <li>Payout Ethereum = Major Jackpot</li>
            </ol>

            <h4>Minor Jackpot</h4>
            <ol>
            <li>Funded by 10% backfire chance on aimed shots</li>
            <li>Sent to round validator</li>
            <li>Payout P3X = Minor Jackpot</li>
            </ol>

          </div>
        </div>
    )
}

export default RulesDetail;

const Nav = ({header,icon}) => {
    return (
        <div className="detail-nav">
            <div className="item1">
            <Link to="/rules"><img src={IconArrowBack} alt="back"/></Link>
            <h1 className="header-title" style={{marginBottom:0}}>{header}</h1>
            </div>
            <div>
            <img src={icon} alt={`icon-${header}`}/>
            </div>
        </div>
    );
}
