import React from 'react';
import './style.scss';
import { NavLink } from 'react-router-dom';

//import Icons
// import PlayIcon from '../../assets/icons/icon-play.svg';
// import TimerIcon from '../../assets/icons/icon-waitlist.svg';
// import ResultIcon from '../../assets/icons/icon-results.svg';
// import RulesIcon from '../../assets/icons/icon-rules.svg';
// import BalanceIcon from '../../assets/icons/icon-balance.svg';

import { ReactComponent as PlayIcon} from '../../assets/icons/icon-play.svg';
import { ReactComponent as TimerIcon} from '../../assets/icons/icon-waitlist.svg';
import { ReactComponent as ResultIcon} from '../../assets/icons/icon-results.svg';
import { ReactComponent as RulesIcon} from '../../assets/icons/icon-rules.svg';
import { ReactComponent as BalanceIcon} from '../../assets/icons/icon-balance.svg';

//import Icon
import Icon from '../../components/Icon/icon';

const bottomNav = () => {
        return (
            <div className="bottom_nav">
                <NavLink to="/" exact activeClassName="menu-active" >
                    <Icon title="Play" icon={<PlayIcon className="svg-icon"/>}/>
                </NavLink>

                <NavLink to="/waitlist" exact activeClassName="menu-active" >
                    <Icon title="Waitlist" icon={<TimerIcon className="svg-icon"/>}/>
                </NavLink>

                <NavLink to="/results" exact activeClassName="menu-active" >
                    <Icon title="Results" icon={<ResultIcon className="svg-icon"/>}/>
                </NavLink>

                <NavLink to="/rules"  activeClassName="menu-active" >
                    <Icon title="Rules" icon={<RulesIcon className="svg-icon"/>}/>
                </NavLink>

                <NavLink to="/balance" exact activeClassName="menu-active" >
                    <Icon title="Balance" icon={<BalanceIcon className="svg-icon"/>}/>
                </NavLink>
            </div>
        )

}

export default bottomNav;
