import React from 'react';
import './style.scss';

const playCards = ({icon,text,click}) => {
    return (
        <div className="play-cards" onClick={click}>
            <div className="icon-box">
                <img src={icon} alt={`icon-${text}`}/>
            </div>
            <div className="text-box">
                <p>{text}</p>
            </div>
        </div>
    )
}

export default playCards;
