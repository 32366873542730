import React from 'react';
import './style.scss';


const Icon = ({title,icon}) => {
    return (
        <div className="icon">
            {/* <img src={icon} alt={`icon-${title}`}/> */}
            {icon}
           <p className="title">{title}</p> 
        </div>
    )
}

export default Icon;
