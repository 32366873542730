import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';

const ruleCard = ({title,icon,linkTo}) => {
    return (
        <Link to={`/rules/${linkTo}`}>
        <div className="rule-card">
            <p className="title">{title}</p>
            <img src={icon} alt={`icon-${title}`}/>
        </div>
        </Link>
    )
}

export default ruleCard;
