import React from 'react';
import { DrizzleContext } from "drizzle-react";
import { newContextComponents } from "drizzle-react-components";

import { Link } from 'react-router-dom';
import './style.scss';

import BalanceCard from '../../components/Balance/balanceCard';
import RewardCard from '../../components/rewardCard/rewardCard';

import IconEtherium from '../../assets/icons/icon-etherium.svg';
import IconToken from '../../assets/icons/icon-token.svg';
import IconLockedReward from '../../assets/icons/icon-lockedreward.svg';
import IconUnlockedReward from '../../assets/icons/icon-unlocked.svg';
import Button from '../../components/button/Button';

const { ContractData } = newContextComponents;



const renderEthBalance = data => {
  const toEther = +window.web3._extend.utils.fromWei(data, 'ether');
  let toEtherFixed = toEther.toFixed(3)
  return <BalanceCard price={toEtherFixed} title="Ethereum" icon={IconEtherium}/>
//  return (<div>eth: <b>{toEtherFixed}</b></div>)
};


const renderP3xBalance = data => {
  //console.log(data)
  const toEther = +window.web3._extend.utils.fromWei(data, 'ether');
  let toEtherFixed = toEther.toFixed(3)
   return <BalanceCard price={toEtherFixed} title="Token" icon={IconToken}/>
//  return (<div>p3x: <b>{toEtherFixed}</b></div>)
};




export default () => (
  <DrizzleContext.Consumer>
    {drizzleContext => {
      const { drizzle, drizzleState, initialized } = drizzleContext;



      if (!initialized) {
        return "Loading...";
      }

      if (!drizzleState.drizzleStatus.initialized) {
        return "Loading...";
      }

      const Withdraw = () => {

       drizzle.contracts.Truel.methods.withdraw.cacheSend()
      }



      const renderValidator = data => {
        if(data === true) {
          return (
              <div className="rewards-unlocked>">
              <Link to="/rewards">
              <h1 className="header-title">Rewards Unlocked
              <img src={IconUnlockedReward} alt="reward-card-icon"/></h1>
              </Link>
              </div>
          )
        }

        if(data === false) {
          return (
              <div className="rewards-unlocked>">
              <h1 className="header-title">Rewards</h1>
              <RewardCard icon={IconLockedReward}/>
              </div>
          )
        }

      };



      return (
        <div className="balance">
         <h1 className="header-title">3Duel Balance</h1>

         <div className="top">
         <ContractData
           drizzle={drizzle}
           drizzleState={drizzleState}
           contract="Truel"
           method="getEthBalance"
           render={renderEthBalance}
          // render={data => <BalanceCard price={renderEthBalance(data[0])} title="Ethereum" icon={IconEtherium}/>}
         />
         <ContractData
           drizzle={drizzle}
           drizzleState={drizzleState}
           contract="Truel"
           method="getBalance"
           render={renderP3xBalance}
         />
         </div>
         <div className="button-wrapper">
            <Button title="Withdraw" click={Withdraw}/>
         </div>


         <ContractData
          drizzle={drizzle}
          drizzleState={drizzleState}
          contract="Truel"
          method="isValidator"
          render={renderValidator}
        />

        </div>
      );
    }}
  </DrizzleContext.Consumer>
)
