import React from 'react';
import './style.scss';
import RoundCard from '../roundCard/roundCard';
import sampleImage from '../../assets/images/player.svg';
import Loader from '../loaderWaiting/loader'
import { DrizzleContext } from "drizzle-react";
import Blockies from 'react-blockies';
import ContractData from '../ContractData/ContractData';
import { getName } from '../../middleware'


export default ({header,count, start, end}) => (

  <DrizzleContext.Consumer>

    {drizzleContext => {
      const { drizzle, drizzleState, initialized } = drizzleContext;

      if (!initialized) {
        return "Loading...";
      }

      if (!drizzleState.drizzleStatus.initialized) {
        return "Loading...";
      }



      let x = drizzleState.contracts.Truel.nextToValidate;


      const myRender = data => {

//        console.log(data)
        let res1 = 'Alive';
        let res2 = 'Alive';
        let res3 = 'Alive';

        if(data[1] === true) {
          res1 = 'Dead'
        }

        if(data[3] === true) {
          res2 = 'Dead'
        }

        if(data[5] === true) {
          res3 = 'Dead'
        }


        return (
          <tr>
              <td>
                <p className="round-nom">{data[7]}</p>
              </td>
              <td>
                  <RoundCard text={res1} img={sampleImage}
                  address={getName(drizzle.store.getState().nameReducer, data[0])}/>
              </td>
              <td>
                  <RoundCard text={res2} img={sampleImage} address={getName(drizzle.store.getState().nameReducer, data[2])}/>
              </td>
              <td>
                  <RoundCard text={res3} img={sampleImage} address={getName(drizzle.store.getState().nameReducer, data[4])}/>
              </td>
          </tr>
        )
      };

      const renderItems = (number) => {

//        console.log(number.validate)
  //      let loopLimit = number.validate;

    //    if(number.validate > 10) {
    //      loopLimit = 10;
    //    }

        let children = []

        for (let j = start; j < end+1; j++) {
    //      console.log(`${j}`)
        //  console.log(start)
          let m = [`${j}`]
        //  console.log(m)
          const con = <ContractData
                          key={j}
                          drizzle={drizzle}
                          drizzleState={drizzleState}
                          contract="Truel"
                          methodArgs={m}
                          method="getBlockFromQueue"
              //            render={data => <div></div>}
                          render={myRender}
                        />

          children.push(con)
        }



        return children
      }


      if(start < 0) {
        return (
          <div><Loader /></div>
        )
      }

      return (
        <div className="table-responsive">
        <table className="table">
            <thead>
                <tr>
                {header && header.map((item,index) => {
                    return <th id="th-empty" key={index}><p className="round-header">{item.name}</p></th>
                })}
                </tr>
            </thead>
            <tbody>
                {renderItems(start,end)}
            </tbody>
        </table>
        </div>
      );
    }}
  </DrizzleContext.Consumer>
);
