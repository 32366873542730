import React from 'react';
import { DrizzleContext } from "drizzle-react";
import { newContextComponents } from "drizzle-react-components";
import './style.scss';

import { Link } from 'react-router-dom';
import RewardCard from '../../components/rewardCard/rewardCard';

import IconLockedReward from '../../assets/icons/icon-lockedreward.svg';
import IconToken from '../../assets/icons/icon-token.svg';
import IconUnlockedReward from '../../assets/icons/icon-unlocked.svg';
import './style.scss';

import Button from '../../components/button/Button';

const { ContractData } = newContextComponents;


export default () => (
  <DrizzleContext.Consumer>
    {drizzleContext => {
      const { drizzle, drizzleState, initialized } = drizzleContext;



      if (!initialized) {
        return "Loading...";
      }

      if (!drizzleState.drizzleStatus.initialized) {
        return "Loading...";
      }



  //    console.log(drizzle.store.getState().contracts.Truel.isValidator['0x0'].value)
/*
      let x = drizzleState.contracts.Truel.waitingListMoveCount;

      let waitlistMoveCount = 0;
      let waitlistMoveCount2 = 1;
      let waitlistMoveCount3 = 2;
      let waitlistMoveCount4 = 3;
      let waitlistMoveCount5 = 3;

      if(x['0x0'] !== undefined) {
        waitlistMoveCount = +x['0x0'].value;
        waitlistMoveCount2 = +waitlistMoveCount + 1;
        waitlistMoveCount3 = +waitlistMoveCount + 2;
        waitlistMoveCount4 = +waitlistMoveCount + 3;
        waitlistMoveCount5 = +waitlistMoveCount + 4;
      }
*/
      const CreateRound = () => {
        //  console.log('1')
          drizzle.contracts.Truel.methods.enterFromWaitlist.cacheSend()
      }
      const FinishRound = () => {

          drizzle.contracts.Truel.methods.validator.cacheSend()
      }

//drizzle.store.getState().contracts.Truel.isValidator['0x0'].value

      const renderCreateButton = data => {
      //  console.log(data)
        if(data === true) {
          return (
            <div className="reward-cardn">
                <div>
                <Button className="redeem-buttonn" title="Create Rnd" click={CreateRound} />
                <p>{'Creating Rounds is our highest reward. Good Luck!'}</p>
                </div>
            </div>
          )
        }

        return (
          <div className="reward-cardn">
          <div>
          <Button className="redeem-buttonn" title={'Unavailable'} />
          <p>{'Create Round impossible'}</p>

          </div>
          </div>
          )
      };




    const renderFinishButton = data => {
      //console.log(data)
      if(data === true) {
          return (
              <div className="reward-cardn">
              <div>
              <Button title={'Finish Rnd'} click={FinishRound} />
              <p>{'Finish Round is where the magic happens. Good luck!'}</p>

              </div>
            </div>
          )
        }

        return (
          <div className="reward-cardn">
          <div>
          <Button title={'Unavailable'}/>
          <p>{'Finish Round impossible'}</p>

          </div>
          </div>
        )
      };


      const renderValidatorBag = data => {
          const toEther = drizzle.web3.extend.utils.fromWei(data, 'ether');

          return (
            <div>
            <h2 className="header-titlen"><img src={IconToken} width={'18px'} alt={`${toEther} p3x unlocks rewards`}/> Unlock Rewards <img src={IconToken} width={'18px'} alt={`${toEther} p3x unlocks rewards`}/></h2>
            </div>
          )
      }

      const renderValidator = data => {
//        const p3xmin = drizzleState.contracts.Truel.validatorBagMin['0x0'].value
      //  const toEther = drizzle.web3.extend.utils.fromWei(p3x, 'ether');
      //  console.log(toEther)

        if(data === true) {
          return (
              <div>
              <ContractData
              drizzle={drizzle}
              drizzleState={drizzleState}
              contract="Truel"
              method="canCreateRound"
              render={renderCreateButton}
              />
              <ContractData
              drizzle={drizzle}
              drizzleState={drizzleState}
              contract="Truel"
              method="isValidatorAvailable"
              render={renderFinishButton}
              />
              </div>
          )
        }

        if(data === false) {
          return (
              <div>
              <h1 className="header-title">Rewards Locked</h1>
              <RewardCard icon={IconLockedReward}/>
              </div>
          )
        }

      };


      return (
        <div className="rewards">

            <ContractData
             drizzle={drizzle}
             drizzleState={drizzleState}
             contract="Truel"
             method="validatorBagMin"
             render={renderValidatorBag}
      //       render={renderValidator}
           />

           <ContractData
            drizzle={drizzle}
            drizzleState={drizzleState}
            contract="Truel"
            method="isValidator"
            render={renderValidator}
     //       render={renderValidator}
          />


        </div>
      );
    }}
  </DrizzleContext.Consumer>
)


/*
const Rewards = () => {
    return (
        <div className="rules">
           <h1 className="header-title">Rewards</h1>
        </div>
    )
}

export default Rewards;
*/
