import React from 'react';
import './style.scss';
import RoundCard from '../roundCard/roundCard';
import sampleImage from '../../assets/images/player.svg';
import { DrizzleContext } from "drizzle-react";

import ContractData from '../ContractData/ContractData';
import { getName } from '../../middleware'


export default ({header,count, start, end, locked}) => (

  <DrizzleContext.Consumer>

    {drizzleContext => {
      const { drizzle, drizzleState, initialized } = drizzleContext;

      if (!initialized) {
        return "Loading...";
      }

      if (!drizzleState.drizzleStatus.initialized) {
        return "Loading...";
      }



//      const [page,setPage] = useState(1);
    //  console.log(drizzle)

  //  let x = drizzleState.contracts.Truel.waitingListMoveCount;

//    let waitlistStart = start;
//    let waitlistEnd = end;
  //  console.log(start)

  //    console.log(locked)

  //  console.log(waitlistMoveCount4)

      const myRender = (data, lock) => {


    //    let locked = false;

        if(+lock <= +data[4])
        {
          locked = true;
        }



        let text = 'left';

        if(data[1] === '1') {
          text = 'miss'
        }

        if(data[1] === '2') {
          text = 'right'
        }

        return(
          <tr>
              <td><p className="round-no-waitlist">{data[4]}</p></td>
              <td>
                  <RoundCard text={text}/>
              </td>
              <td className="player">
                <RoundCard
                locked={locked}
                text={getName(drizzle.store.getState().nameReducer, data[0])}/>
              </td>
          </tr>
        )

      };

      const renderItems = (start, end) => {

        let children = []


        for (let j = start; j < end; j++) {
    //      console.log(`${j}`)
          let m = [`${j}`]
//          console.log(m)
          const con = <ContractData
             key={j}
             className="table"
             drizzle={drizzle}
             drizzleState={drizzleState}
             contract="Truel"
             methodArgs={m}
             method="getWaitlistItem"
             render={data => myRender(data,locked)}
           />

          children.push(con)
        }

        return children
      }

      return (
        <div className="table-responsive">
        <table className="table">
            <thead>
                <tr>
                {header && header.map((item,index) => {
                    return <th id="th-empty" key={index}><p className="round-header">{item.name}</p></th>
                })}
                </tr>
            </thead>
            <tbody>
            {renderItems(start,end)}
                {/*data.length === 0 &&<div className="empty-records"><p>No more records</p></div> */}
            </tbody>
        </table>
        </div>
      );
    }}
  </DrizzleContext.Consumer>
);

/*
<ContractData
   className="table"
   drizzle={drizzle}
   drizzleState={drizzleState}
   contract="Truel"
   methodArgs={[waitlistMoveCount.toString()]}
   method="getWaitlistItem"
   render={myRender}
 />
 <ContractData
    className="table"
    drizzle={drizzle}
    drizzleState={drizzleState}
    contract="Truel"
    methodArgs={[waitlistMoveCount2.toString()]}
    method="getWaitlistItem"
    render={myRender}
  />
  <ContractData
     className="table"
     drizzle={drizzle}
     drizzleState={drizzleState}
     contract="Truel"
     methodArgs={[waitlistMoveCount3.toString()]}
     method="getWaitlistItem"
     render={myRender}
   />
   <ContractData
      className="table"
      drizzle={drizzle}
      drizzleState={drizzleState}
      contract="Truel"
      methodArgs={[waitlistMoveCount4.toString()]}
      method="getWaitlistItem"
      render={myRender}
    />
    <ContractData
       className="table"
       drizzle={drizzle}
       drizzleState={drizzleState}
       contract="Truel"
       methodArgs={[waitlistMoveCount5.toString()]}
       method="getWaitlistItem"
       render={myRender}
     />
    */
