import React from 'react';
import './style.scss';
import IconArrowBack from '../../assets/icons/icon-arrowback.svg';
import IconHTP from '../../assets/icons/icon-howtoplay.svg';
import { Link } from 'react-router-dom';

const RulesDetail = () => {
    return (
        <div className="rules-detail">
          <Nav header="Expanded Scenarios" icon={IconHTP}/>
          <div className="text-body">

          <p>*E1 = Latest Waitlist entrant</p>

          <h4>Scenario #1 - One death</h4>
          <ol>
          <li>P1 fires left, mints $2.20 USD of P3X</li>
          <li>P2 fires left, mints $2.20 USD of P3X</li>
          <li>P3 fires miss, mints $2.20 USD of P3X</li>
          <li>Each get $1 P3X & can be withdrawn</li>
          <li>Each lock $1 P3X in round of contract</li>
          <li>Each pay .06 to Grand Jackpot</li>
          <li>Each pay .14 to P3X Holders</li>
          <li>P1 creates a round for P1/P2/P3</li>
          <li>This locks +$1 USD P3X in the round</li>
          <li>P1 finalizes, chance @MinorJackpot</li>
          <li>E1 chance @MajorJackpot</li>
          <li>P1/P2/P3/E1 chance @GrandJackpot</li>
          <li>P1 kills P2, gets P2 locked $1 P3X</li>
          <li>P2 = Dead and cannot fire</li>
          <li>P3 misses collects $1 locked P3X</li>
          <li>P1 validates round $1 USD P3X returned</li>
          <li>P1 Final: $3 P3X</li>
          <li>P2 Final: $1 P3X</li>
          <li>P3 Final: $2 P3X</li>
          </ol>


          <h4>Scenario #2 - Two dead</h4>
          <ol>
          <li>P1 fires left, mints $2.20 USD of P3X</li>
          <li>P2 fires left, mints $2.20 USD of P3X</li>
          <li>P3 fires left, mints $2.20 USD of P3X</li>
          <li>P1 kills P2, P1 gets P2 locked $1 P3X</li>
          <li>P2 = Dead and cannot fire</li>
          <li>P3 kills P1, P3 gets P1 locked $2 P3X</li>
          <li>P1 Final: $1 P3X</li>
          <li>P2 Final: $1 P3X</li>
          <li>P3 Final: $4 P3X</li>
          </ol>

          <h4>Scenario #3 - All live</h4>
          <ol>
          <li>P1 fires miss, mints $2.20 P3X</li>
          <li>P2 fires miss, mints $2.20 P3X</li>
          <li>P3 fires miss, mints $2.20 P3X</li>
          <li>P1 Final: $2 P3X</li>
          <li>P2 Final: $2 P3X</li>
          <li>P3 Final: $2 P3X</li>
          </ol>

          <h4>Scenario #4 - All Dead</h4>
          <ol>
          <li>P1 fires miss, mints $2.20 P3X</li>
          <li>P2 fires miss, mints $2.20 P3X</li>
          <li>P3 fires miss, mints $2.20 P3X</li>
          <li>P1 Final: $1 P3X</li>
          <li>P2 Final: $1 P3X</li>
          <li>P3 Final: $1 P3X</li>
          </ol>

          </div>
        </div>
    )
}

export default RulesDetail;

const Nav = ({header,icon}) => {
    return (
        <div className="detail-nav">
            <div className="item1">
            <Link to="/rules"><img src={IconArrowBack} alt="back"/></Link>
            <h1 className="header-title" style={{marginBottom:0}}>{header}</h1>
            </div>
            <div>
            <img src={icon} alt={`icon-${header}`}/>
            </div>
        </div>
    );
}
