import React from 'react';
// import StarOne from '../../assets/icons/starone.svg';
// import StarTwo from '../../assets/icons/startwo.svg';
import Particles from 'react-particles-js';
import './style.scss';

const headerParticle = () => {
    return (
        <div className="particles">
        <Particles
          className={'particles'}
    params={{
	    "particles": {
	        "number": {
	            "value": 160,
	            "density": {
	                "enable": true,
	                "value_area": 100
	            }
	        },
	        "line_linked": {
	            "enable": false,
	            "opacity": 0.02
	        },
	        "move": {
	            "direction": "none",
	            "speed": 0.05
	        },
	        "size": {
	            "value": 1
	        },
	        "opacity": {
	            "anim": {
	                "enable": true,
	                "speed": 1,
	                "opacity_min": 0.05
	            }
	        }
	    },
	    "interactivity": {
	        "events": {
	            "onclick": {
	                "enable": false,
	                "mode": "push"
	            }
	        },
	        "modes": {
	            "push": {
	                "particles_nb": 1
	            }
	        }
	    },
	    "retina_detect": true
	}} />
        </div>
    )
}

export default headerParticle;
