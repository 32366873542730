import Truel from './contracts/Truel';
import Names from './contracts/Names';
//import { abi as namesABI } from "./contracts/Names.json"
import { deployedBytecode as namesByteCode } from "./contracts/Names.json"
import { Drizzle, generateStore, EventActions } from 'drizzle'
//import PlayerBook from './contracts/PlayerBook';
/*
import { getWeb3 } from './middleware'
const web3 = await getWeb3();
const contractAddress = '0x5bdd5745e2dbe04d16a0e9d2fd0607a8f7bf09f4';
const web3Contract = new web3.eth.Contract(namesABI, contractAddress, {data: namesByteCode })
let contractConfig = {
  contractName: "Names",
  web3Contract: web3Contract
}
*/
//import { getWeb3 } from './middleware'
//const Web3 = require('web3');
//not used

const ropstenContractAddress = '0x5bdd5745e2dbe04d16a0e9d2fd0607a8f7bf09f4';
const mainContractAddress = '0x7e002c7c2b0c2e821ea68d56489bd18c8cd79ab1';

const Web3 = require('web3');

//let web3 = new Web3(window.web3.currentProvider);
//const web3 = getWeb3()

let options = {
  web3: {
    block: false,
//    customProvider: new Web3(new Web3.providers.WebsocketProvider(ws_provider)),
    fallback: {
     type: 'ws',
     url: 'ws://127.0.0.1:8545'
    }
  },
  contracts: [Truel, /*contractConfig /*NamesContract , Pledge , PlayerBookContract */],
  events: {
    Truel: ["WaitlistEntered", "RoundEntered", "PlayerShot", "Jackpot", "OnWithdraw"],
//    Names: ["OnNewName"]
//    event
  },
  polls: {
    accounts: 10000,
  },
};



let namesContract = {};

let contractConfig = {}


if (typeof window.web3 !== 'undefined') {
  let web3 = new Web3(window.web3.currentProvider);
  namesContract = new web3.eth.Contract(Names.abi, (web3._provider.networkVersion === 1) ? mainContractAddress : ropstenContractAddress );

  contractConfig = {
    contractName: "Names",
    web3Contract: namesContract
  }

  options = {
    web3: {
      block: false,
  //    customProvider: new Web3(new Web3.providers.WebsocketProvider(ws_provider)),
      fallback: {
       type: 'ws',
       url: 'ws://127.0.0.1:8545'
     }
    },
    contracts: [Truel, contractConfig /*NamesContract , Pledge , PlayerBookContract */],
    events: {
      Truel: ["WaitlistEntered", "RoundEntered", "PlayerShot", "Jackpot", "OnWithdraw"],
  //    Names: ["OnNewName"]
  //    event
    },
    polls: {
      accounts: 10000,
    },
  };


}


export default options;
