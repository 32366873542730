import React from 'react';
import './style.scss';

const Button = ({title,className,click}) => {
    return (
        <button className={`custom-button ${className}`} onClick={click}>
        {title}
        </button>
    )
}

export default Button;
