import React from 'react';
import { DrizzleContext } from "drizzle-react";

import './style.scss';
import EtheriumIcon from '../../assets/icons/icon-etherium-green.svg';
import TokenIcon from '../../assets/icons/icon-token.svg';
import ContractData from '../ContractData/ContractData';



export default ({header,count}) => (

  <DrizzleContext.Consumer>

    {drizzleContext => {
      const { drizzle, drizzleState, initialized } = drizzleContext;

      if (!initialized) {
        return "Loading...";
      }

      if (!drizzleState.drizzleStatus.initialized) {
        return "Loading...";
      }


      return (
        <div className="header">
            <div>
                <h1 className="ligt-head">  <img src={EtheriumIcon} alt="ether-icon"/> {" "}MAJOR</h1>
                <ContractData
                  drizzle={drizzle}
                  drizzleState={drizzleState}
                  contract="Truel"
                  method="MINI_JACKPOT"
                  render={data =>  <h1 className="white-head end">{retbal(data)}</h1>}
                />
            </div>
            <div>
                <h1 className="ligt-head"><img src={EtheriumIcon} alt="ether-icon"/> {" "}GRAND</h1>
                <ContractData
                  drizzle={drizzle}
                  drizzleState={drizzleState}
                  contract="Truel"
                  method="DIVIDENDS_JACKPOT"
                  render={data =>  <h1 className="white-head end">{retbal(data)}</h1>}
                />
            </div>
            <div>
                <h1 className="ligt-head"><img src={TokenIcon} alt="p3x-icon"/> {" "}MINOR</h1>
                <ContractData
                  drizzle={drizzle}
                  drizzleState={drizzleState}
                  contract="Truel"
                  method="BACKFIRE_JACKPOT"
                  render={data =>  <h1 className="white-head end">{retbal(data)}</h1>}

                />
            </div>
        </div>
      );
    }}
  </DrizzleContext.Consumer>
);

const countDecimals = function(value) {

    return value.toString().split(".")[0];
}

const retbal = data => {
  const toEther = +window.web3._extend.utils.fromWei(data, 'ether');

    if(countDecimals(toEther) > 2) {
      let toEtherFixed = toEther.toFixed(2)
      return toEtherFixed
    }

    if(countDecimals(toEther) > 3) {
      let toEtherFixed = toEther.toFixed(1)
      return toEtherFixed
    }

  let toEtherFixed = toEther.toFixed(3)

  return toEtherFixed
};

/*
const Header = () => {
    return (
        <div className="header">
            <div>
                <h1 className="ligt-head">MAJOR</h1>
                <h1 className="white-head">10.50</h1>
            </div>
            <div>
                <h1 className="ligt-head">GRAND</h1>
                <h1 className="white-head">10.1101</h1>
            </div>
            <div>
                <h1 className="ligt-head">MINOR</h1>
                <h1 className="white-head end">0.900</h1>
            </div>
        </div>
    )
}

export default Header;

*/

/*
<ContractData
  drizzle={drizzle}
  drizzleState={drizzleState}
  contract="Truel"
  method="DIVIDENDS_JACKPOT"
  render={data =>  <ScoreCard logo={EtheriumIcon} image={GrandIcon} number={retbal(data)} />}
/>

<div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>

<ContractData
  drizzle={drizzle}
  drizzleState={drizzleState}
  contract="Truel"
  method="MINI_JACKPOT"
  render={data =>      <ScoreCard key={1} logo={EtheriumIcon} number={retbal(data)} title="MAJOR"/>}
/>


<ContractData
  drizzle={drizzle}
  drizzleState={drizzleState}
  contract="Truel"
  method="BACKFIRE_JACKPOT"
  render={data =>  <ScoreCard key={2} logo={TokenIcon} number={retbal(data)} title="MINOR"/>}
/>


const retbal = data => {
  const toEther = +window.web3._extend.utils.fromWei(data, 'ether');
  let toEtherFixed = toEther.toFixed(3)

  return toEtherFixed
};

*/
