import React, { useState } from 'react';
import { DrizzleContext } from "drizzle-react";
import { newContextComponents } from "drizzle-react-components";

import { Link } from 'react-router-dom';
import './style.scss';

import IconLockedReward from '../../assets/icons/icon-lockedreward.svg';
import IconUnlockedReward from '../../assets/icons/icon-unlocked.svg';
import Button from '../../components/button/Button';

const { ContractData } = newContextComponents;

const renderBea = data => {
//  console.log(data)
  return <div></div>
}


const CreateName = () => {
  const [name, setName] = useState('');

  const handleChange = event => setName(event.target.value);

  return (
    <DrizzleContext.Consumer>
      {drizzleContext => {
        const { drizzle, drizzleState, initialized } = drizzleContext;



        if (!initialized) {
          return "Loading...";
        }

        if (!drizzleState.drizzleStatus.initialized) {
          return "Loading...";
        }


        const Create = () => {


        if (!drizzleState.drizzleStatus.initialized) {
          return null;
        }

          //const enter =
          const account = drizzleState.accounts[0];

    //      console.log(drizzle)
    //      console.log(drizzleState)
          const nameFee = drizzleState.contracts.Names.getNameFee['0x0'].value;
    //      console.log(nameFee)
          drizzle.contracts.Names.methods.registerNameCore.cacheSend(name , {from: account, value: nameFee})
        }


        return (
          <div className="balance">
           <h1 className="header-title">Create Username</h1>

           <div className="top">
           <ContractData
             drizzle={drizzle}
             drizzleState={drizzleState}
             contract="Names"
             method="getNameFee"
  //           method="getPlayerName"
  //           methodArgs={account}
             render={renderBea}
            // render={data => <BalanceCard price={renderEthBalance(data[0])} title="Ethereum" icon={IconEtherium}/>}
           />

           </div>
            <div className="input-wrapper">
              <input type="text" value={name} onChange={handleChange} />
            </div>
           <div className="button-wrapper">
              <Button title="Create Name" click={Create}/>
           </div>


          </div>
        );
      }}
    </DrizzleContext.Consumer>
  );
};

export default CreateName;
