import { put, takeEvery, take, fork, takeLatest } from 'redux-saga/effects'
import { Drizzle, generateStore, EventActions } from 'drizzle'
import Names from '../contracts/Names';
import Truel from '../contracts/Truel.json'
import { abi as namesABI } from "../contracts/Names.json"
import drizzleOptions from '../drizzleOptions'
import { rootSaga } from '../services/sagas';
import data from './players.json';
import _ from 'lodash';

import Web3 from 'web3';

const insulter = require('insult');
//const Web3 = require('aion-web3');
const web3 = getWeb3()


const ropstenContractAddress = '0x5bdd5745e2dbe04d16a0e9d2fd0607a8f7bf09f4';
const mainContractAddress = '0x7e002c7c2b0c2e821ea68d56489bd18c8cd79ab1';


const ropsten3DuelContractAddress = '0x128A199D9F53B59e4a9f13Bfd1c6D6486897a9d0';
const main3DuelContractAddress = '0xD11851c8Ba1dcEB72207aCB63fAa93253D57Cc00';

let truelContract = null
let namesContract = null


if (web3 !== null) {
  truelContract = new web3.eth.Contract(Truel.abi, (web3._provider.networkVersion === 1) ? main3DuelContractAddress : ropsten3DuelContractAddress );
  namesContract = new web3.eth.Contract(Names.abi, (web3._provider.networkVersion === 1) ? mainContractAddress : ropstenContractAddress );
}

let processed_events = new Set();

export function getWeb3() {

    if (typeof window.web3 === 'undefined') {
      // no web3, use fallback
      return null
    }

    if (window.web3.currentProvider.isTrust) {
    //  console.log('trust')

    //  if(window.web3._provider.networkVersion === 1)
    //  {
        let ws_provider = 'wss://mainnet.infura.io/ws/v3/c50d4019b46f48d68bc8432b45b75078'
        return new Web3(new Web3.providers.WebsocketProvider(ws_provider))
    //  }

      //if(window.web3._provider.networkVersion === 3)
    //  {
      //  let ws_provider = 'wss://ropsten.infura.io/ws/v3/c50d4019b46f48d68bc8432b45b75078'
    //    return new Web3(new Web3.providers.WebsocketProvider(ws_provider))
    //  }
//      return new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/metamask'))
  //    return new Web3(new Web3.providers.HttpProvider('http://localhost:8545'))
      //https://mainnet.infura.io/v3/c50d4019b46f48d68bc8432b45b75078

      //  return new Web3(window.web3.currentProvider)
    }

    if(window.web3.currentProvider.isMetaMask) {
  //    console.log('metamask')
      //var ws_provider = 'wss://ropsten.infura.io/ws/v3/c50d4019b46f48d68bc8432b45b75078'
      //return new Web3(new Web3.providers.WebsocketProvider(ws_provider))

      return new Web3(window.web3.currentProvider)
    }


    if ((typeof window.ethereum !== 'undefined') || (typeof window.web3 !== 'undefined')) {

      let ws_provider = 'wss://mainnet.infura.io/ws/v3/c50d4019b46f48d68bc8432b45b75078'
      return new Web3(new Web3.providers.WebsocketProvider(ws_provider))
  
    } else {

      let ws_provider = 'wss://mainnet.infura.io/ws/v3/c50d4019b46f48d68bc8432b45b75078'
      return new Web3(new Web3.providers.WebsocketProvider(ws_provider))
    }
}




export function getName(state, id) {
//  console.log(web3.eth.accounts.givenProvider.selectedAddress)
  //console.log(id  + ' ' + web3.eth.accounts.givenProvider.selectedAddress)
  if(web3.eth.accounts.givenProvider.selectedAddress === null)
  {
    return null
  }

  let nameObject = _.find(state.names, {id: id});
  if(nameObject === undefined)
  {
    //console.log('unknown player')
    if(web3.eth.accounts.givenProvider.selectedAddress.toLowerCase() === id.toLowerCase()) {

      const name = '(YOU)'
      return name;
    }
    return 'unknown player (' + id.substring(0,9) + ')'
  }
  if(web3.eth.accounts.givenProvider.selectedAddress.toLowerCase() === id.toLowerCase()) {

    return nameObject.name.concat(' (YOU)')
  }
  return nameObject.name
}


/*
const web3 = drizzle.web3;
const contract = drizzle.contracts.Registrar;
const yourContractWeb3 = new web3.eth.Contract(contract.abi, contract.address);
const eventOptions = {
   fromBlock: 7228340
   //   toBlock: 'latest'
 };
 const eventsArray = await this.getPastEvents('Registrar', 'BidRevealed', eventOptions);
 */


const contractEventNotifier = store => next => action => {

  if (action.type === 'WEB3_INITIALIZED') {

  //  console.log(action)
  }


  if (action.type === 'DRIZZLE_INITIALIZED') {

    /*
    event Jackpot(uint indexed roundNumber, address indexed player, uint indexed amount);
    event OnWithdraw(address indexed customerAddress, uint256 ethereumWithdrawn, uint256 p3xWithdrawn);
    event WaitlistEntered(address player);
    event RoundEntered(uint indexed roundNumber, address indexed player);
    event PlayerShot(uint indexed roundNumber, address indexed shooter, address indexed dead);
    */
    //console.log(web3)
    const playerAddress = web3.eth.accounts.givenProvider.selectedAddress;
    //.toLowerCase()

//0x79d8B30554F75dC40ae734407C185e7dc974Ff96
//0x0d68C987EDb915cC729cD754788205D5BFA5F9da

    truelContract.getPastEvents('RoundEntered',{ filter:
        { player: playerAddress }, fromBlock: 0, toBlock: 'latest' },(error, result) => {
        if (error) { console.log('error event ' + error) }
        if (!error) {
          var obj=JSON.parse(JSON.stringify(result));

          const entries = obj.length;
          const message = `You have entered ${entries} Duels.`
        //  console.log(message)
          store.dispatch({type: ADD_MESSAGE, text: message, id: Date.now(), stage:'STATS' })
        }
    });

    truelContract.getPastEvents('PlayerShot',{ filter:
        { shooter: playerAddress }, fromBlock: 0, toBlock: 'latest' },(error, result) => {
        if (error) { console.log('error event ' + error) }
        if (!error) {
          var obj=JSON.parse(JSON.stringify(result));

          const entries = obj.length;
          const message = `You have shot ${entries} players.`
      //    console.log(message)
          store.dispatch({type: ADD_MESSAGE, text: message, id: Date.now(), stage:'STATS' })
        }
    });

    truelContract.getPastEvents('PlayerShot',{ filter:
        { dead: playerAddress }, fromBlock: 0, toBlock: 'latest' },(error, result) => {
        if (error) { console.log('error event ' + error) }
        if (!error) {
          var obj=JSON.parse(JSON.stringify(result));

          const entries = obj.length;
          const message = `You have died ${entries} times.`
        //  console.log(message)
          store.dispatch({type: ADD_MESSAGE, text: message, id: Date.now(), stage:'STATS' })
        //  store.dispatch({type: ADD_MESSAGE, text: 'Get revenge?', id: Date.now(), stage:'STATS' })
        }
    });


  }


  if (action.type === EventActions.EVENT_FIRED) {

      if (processed_events.has(action.event.id)) {
        return;
      }

      processed_events.add(action.event.id);
      //end workaround


      if(action.event.event === 'RoundEntered') {

        const playerAddress = action.event.returnValues.player;
        let player = getName(store.getState().nameReducer, action.event.returnValues.player);

        if(player.includes('unknown player (')) {
          store.dispatch({type: FIND_NAME, address: playerAddress, id: Date.now(), stage:'NEW_PLAYER' })
        }

        const message = `#${action.event.returnValues.roundNumber} round created by ${player}`
        store.dispatch({type: ADD_MESSAGE, text: message, id: Date.now(), stage:'ROUND_ENTERED' })

//        console.log(message)
      }

      if(action.event.event === 'WaitlistEntered') {
        const playerAddress = action.event.returnValues.player;

        let player = getName(store.getState().nameReducer, playerAddress);

        if(player.includes('unknown player (')) {
          store.dispatch({type: FIND_NAME, address: playerAddress, id: Date.now(), stage:'NEW_PLAYER' })
        }

        if(store.getState().accounts[0] === playerAddress) {
          const insult = insulter.Insult().toLowerCase();
          const message = `(YOU) ${insult} `;

          store.dispatch({type: ADD_MESSAGE, text: message, id: Date.now(), stage: 'WAITLIST_ENTERED'})
        }

        const message = `#${action.event.blockNumber} ${player} entered the fray`;
        store.dispatch({type: ADD_MESSAGE, text: message, id: Date.now(), stage: 'WAITLIST_ENTERED'})
        store.dispatch({type: 'LEADER_CHANGE', id: Date.now(), address: playerAddress, name: player, stage:'LEADER_CHANGE' })

        const val_grand = web3.extend.utils.fromWei(store.getState().contracts.Truel.DIVIDENDS_JACKPOT['0x0'].value, 'ether');
        const val_major = web3.extend.utils.fromWei(store.getState().contracts.Truel.MINI_JACKPOT['0x0'].value, 'ether');
        //const val_grand = 100.00
        //const val_major = 1.00
        const total = +(val_grand/4) + +val_major
    //    console.log('jackpot totals: ' + val_grand)
    //    console.log('jackpot totals: ' + val_major)
    //    console.log('jackpot totals: ' + total)
        const toEtherFixed = total.toFixed(3)
        //web3._extend.utils.fromWei(data, 'ether');
        const messageLeaderChange = `[LEADER] ${player} eligible for ${toEtherFixed} Ethereum on next round close`;

        store.dispatch({type: 'ADD_MESSAGE_FRONT', text: messageLeaderChange, id: Date.now()+10000, stage: 'XXXX'})

      }

      if(action.event.event === 'PlayerShot') {
        const shooterAddress = action.event.returnValues.shooter
        const deadAddress = action.event.returnValues.dead
        let dead = getName(store.getState().nameReducer, deadAddress)
        let shooter = getName(store.getState().nameReducer, shooterAddress)

        if(dead.includes('unknown player (')) {
          store.dispatch({type: FIND_NAME, address: deadAddress, id: Date.now(), stage:'NEW_PLAYER' })
        }

        if(shooter.includes('unknown player (')) {
          store.dispatch({type: FIND_NAME, address: shooterAddress, id: Date.now(), stage:'NEW_PLAYER' })
        }
        //console.log(store.getState().accounts[0])
        if(store.getState().accounts[0] === shooterAddress) {
          const insult = insulter.Insult().toLowerCase();
          const message = `ROFLLLLLLLL - You finally killed someone. ${insult}`
          store.dispatch({type: ADD_MESSAGE, text: message, id: Date.now(), stage: 'PLAYER_SHOT'})
        }

        if(store.getState().accounts[0] === deadAddress) {
          const message = `3DUEL GODS - "We are actually glad you died. TBH... you suck"`
          store.dispatch({type: ADD_MESSAGE, text: message, id: Date.now(), stage: 'PLAYER_SHOT'})
        }

        const message = `#${action.event.returnValues.roundNumber} ${shooter} shot ${dead}`
        store.dispatch({type: ADD_MESSAGE, text: message, id: Date.now(), stage: 'PLAYER_SHOT'})
    //    console.log(message)
      }

      if(action.event.event === 'Jackpot') {
        const playerAddress = action.event.returnValues.player
        const winAmount = action.event.returnValues.amount
        const roundNumber = action.event.returnValues.roundNumber
        let player = getName(store.getState().nameReducer, playerAddress)

        if(player.includes('unknown player (')) {
          store.dispatch({type: FIND_NAME, address: playerAddress, id: Date.now(), stage:'NEW_PLAYER' })
        }

        const toEther = +window.web3._extend.utils.fromWei(winAmount, 'ether');
        const toEtherFixed = toEther.toFixed(3)

        const message = `#${roundNumber} congrats ${player} on the ${toEtherFixed} Jackpot Win`
        store.dispatch({type: ADD_MESSAGE, text: message, id: Date.now(), stage: 'JACKPOT_WIN'})
    //    console.log(message)
      }

  }



  return next(action)
}



const appMiddlewares = [ contractEventNotifier ]


const initialState = {
  messages: [
    {id: Date.now(), text: 'Why not choose a name?', stage: 'NEW_NAME'},
    {id: Date.now(), text: 'Welcome to 3Duel.', stage: 'INIT'},
  ]
}

//    drizzle.store.dispatch({type: 'ADD_MESSAGE', text: 'GET A NAME', id: Date.now(), stage: 'NEW_NAME'})
const FETCH_MESSAGE = 'FETCH_MESSAGE'
const ADD_MESSAGE = 'ADD_MESSAGE'
const DELETE_MESSAGE = 'DELETE_MESSAGE'

// reducers
export const messageReducer = (state = initialState , action) => {
  switch (action.type) {
      case 'ADD_MESSAGE': {
    //  console.log(action)
        return Object.assign({}, state, {
          messages: state.messages.concat({
            id: action.id,
            text: action.text,
            type: action.type,
            stage: action.stage
          })
        })
      }
      case 'DELETE_MESSAGE': {
    //  console.log(action)
        state.messages.shift();
        return state
      }
      case 'ADD_MESSAGE_FRONT': {
    //  console.log(action)
      const object = {
        id: action.id,
        text: action.text,
        type: action.type,
        stage: action.stage
      }
      let arr = state.messages;
      arr.unshift(object)
      return Object.assign({}, state, {
        messages: arr
      })
      }
      default:
        return state
    }
}


let arr = [];

Object.keys(data).forEach(function(key,index) {

    let item = {id: data[key].id,name: data[key].name}
    arr[index] = item

});


const initialNamesState = {
  names: arr,
  leader: {id: '0x01', name: 'unknown'}
//    {id: "0x0d68C987EDb915cC729cD754788205D5BFA5F9da", name: 'trever',  stage: 'INIT'},
}

const ADD_NAME = 'ADD_NAME'
const FIND_NAME = 'FIND_NAME'
const UPDATE_LEADER = 'UPDATE_LEADER'
const LEADER_CHANGE = 'LEADER_CHANGE'
//const FIND_NEW_NAME = 'FIND_NEW_NAME'
// reducer
export const nameReducer = (state = initialNamesState , action) => {
  switch (action.type) {
      case 'ADD_NAME':

        let nameObject = _.find(state.names, {id: action.id});
      //  console.log(state.names)
      //  console.log(action.id)
      //  console.log(nameObject)

        if(nameObject !== undefined) {
          let nameIndex = _.findIndex(state.names, {id: action.id});
          state.names.splice(nameIndex, 1);
        //  return state;
        }

        return Object.assign({}, state, {
          names: [
             ...state.names,
             {
               id: action.id, name: action.name
             }
          ],
          leader: state.leader
        });
      case 'FIND_NAME':
        return state;
      case 'LEADER_CHANGE':
        return state;
      case 'UPDATE_LEADER':
      return Object.assign({}, state, {
        names: [...state.names],
        leader: {id: action.id, name: action.name}
      });
      default:
        return state
    }
}



function *fetchMessages() {

 yield put({ type: ADD_MESSAGE })
}

function *findPlayer(action) {

  const address = action.address;
  if(!web3.utils.isAddress(address)) {
  //  console.log('abort')
    return;
  }

  try
  {
    const name = yield namesContract.methods.getPlayerName(address).call()
    const nameAscii = web3.utils.toAscii(name).replace(/[^\x20-\x7E]/g, '')
    //console.log(web3.utils.toAscii(name))
    yield put({type: 'ADD_NAME', name: nameAscii, id: address})
  }
  catch(e)
  {
    console.log('error ' + e)
  }

}

function *updateLeader(action) {

  const address = action.address;

  try
  {
      yield put({type: 'UPDATE_LEADER', id: action.address, name: action.name })
      //console.log('.')
//    leader: {id: action.id, name: action.address}
  }
  catch(e)
  {
    console.log('error ' + e)
  }

}

// app root saga
function *appRootSaga() {
 yield takeEvery(FETCH_MESSAGE, fetchMessages)
 //const watcher = yield fork(findPlayer);
 yield takeEvery(FIND_NAME, findPlayer)
 yield takeLatest(LEADER_CHANGE, updateLeader)
}

const appReducers = { messages: messageReducer, nameReducer }
const appSagas = [appRootSaga]

const store = generateStore({
//  drizzleState,
//  drizzle,
  drizzleOptions,
  appMiddlewares,
  rootSaga,
  appReducers,
  appSagas,
  disableReduxDevTools: false  // enable ReduxDevTools!
})

//store.dispatch({type: DELETE_MESSAGE})

export default store
