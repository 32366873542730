import React from 'react';
import './style.scss';
import IconArrowBack from '../../assets/icons/icon-arrowback.svg';
import IconReward from '../../assets/icons/icon-reward.svg';
import { Link } from 'react-router-dom';

const RulesDetail = () => {
    return (
        <div className="rules-detail">
          <Nav header="Reward" icon={IconReward}/>
          <div className="text-body">
          <ol>
          <li>Unlock rewards by maintaining P3X contract balance</li>
          <li>Round creator stakes P3X as incentive to finalize rounds</li>
          <li>Round creator has first option to finalize round</li>
          <li>Round creator staked P3X is returned regardless of round finalizer</li>
          <li>After 10 blocks anyone may finalize round</li>
          <li>Round finalizer is eligible for Major jackpot</li>
            </ol>
          </div>
        </div>
    )
}

export default RulesDetail;

const Nav = ({header,icon}) => {
    return (
        <div className="detail-nav">
            <div className="item1">
            <Link to="/rules"><img src={IconArrowBack} alt="back"/></Link>
            <h1 className="header-title" style={{marginBottom:0}}>{header}</h1>
            </div>
            <div>
            <img src={icon} alt={`icon-${header}`}/>
            </div>
        </div>
    );
}
