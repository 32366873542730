import React from 'react';
import './style.scss';
const rewardCard = ({icon}) => {
    return (
        <div className="reward-card">
            <img src={icon} alt="reward-card-icon"/>
        </div>
    )
}

export default rewardCard;
