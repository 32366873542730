import React, { useState, useEffect, useReducer, useSelector } from 'react'
//import React from 'react';
import Ticker from 'react-ticker'
import { DrizzleContext } from "drizzle-react";
import { Link } from 'react-router-dom';
//import Button from '../button/Button';
//import { useSelector, useDispatch  } from 'react-redux'

const style = {
        fontSize: '18px',
        marginTop: '10px',
        lineHeight: '11px',
        letterSpacing: '0.25px',
        paddingLeft: '40px',
        paddingRight: '50px',
        color: '#FFFFFF',
        opacity: '0.8',
        whiteSpace: "nowrap"
}

const floating = {
//    height: '100px',
//    display: 'table-cell',
  //  verticalAlign: 'middle'
}

const style2 = {
        fontSize: '18px',
        marginTop: '-5px',
        paddingLeft: '50px',
        paddingRight: '50px',
        lineHeight: '11px',
        letterSpacing: '0.25px',
        color: '#FFFFFF',
        whiteSpace: "nowrap"
}

const style3 = {
  background: '#F91F5C',
  borderColor: '#F91F5C',
  borderRadius: '5px',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '9px',
  letterSpacing: '0.25px',
  color: '#FFFFFF',
  opacity: '0.8',
  width: '119px',
  padding: '5px'
}


export default () => (

  <DrizzleContext.Consumer>

    {drizzleContext => {
      const { drizzle, drizzleState, initialized } = drizzleContext;

      if (!initialized) {
        return "Loading...";
      }

      if (!drizzleState.drizzleStatus.initialized) {
        return "Loading...";
      }


      if(drizzleState.messages.messages.length > 0) {
    //  console.log('1')
      }


    //  console.log(drizzle.store.getState)
//      const [page,setPage] = useState(1);
    //  console.log(drizzle)
    let speed = 12;
    if(drizzleState.messages.messages.length > 30) {
      speed = 15;
    }
    else {
      speed = 12
    }

    function getLink(message) {

      if(drizzleState.messages.messages[0].stage === 'NEW_NAME') {
//        return <Link to={'/name'}><Button title="Create Name Page" /></Link>
        return <Link to={'/name'}><button style={style3}>Create A Name</button></Link>
      }

      if(drizzleState.messages.messages[0].stage === 'JOIN') {
//        return <Link to={'/name'}><Button title="Create Name Page" /></Link>
        return <a target='_blank' rel="noopener noreferrer" href='https://discord.gg/3FqPvkW'><button style={style3}>JOIN DISCORD</button></a>
      }

      if(drizzleState.messages.messages[0].stage === 'INFO_JACKPOTS') {
//        return <Link to={'/name'}><Button title="Create Name Page" /></Link>
        return <Link to={'/rules/jackpots'}><button style={style3}>Rules</button></Link>
      }

      if(drizzleState.messages.messages[0].stage === 'REWARDS') {
//        return <Link to={'/name'}><Button title="Create Name Page" /></Link>
        return <Link to={'/rewards'}><button style={style3}>Rewards</button></Link>
      }
//INFO_JACKPOTS

/*
https://discord.gg/3FqPvkW
*/


      return
    }

    //  console.log(drizzleState.messages.messages[0])
    function getMessage() {

      if((drizzleState.messages.messages[0].id + 5000) < Date.now()) {
        //console.log('old')

        if(drizzleState.messages.messages.length > 1) {
          drizzle.store.dispatch({type: 'DELETE_MESSAGE' })
        }

        return drizzleState.messages.messages[0].text
  //    return newName
      }

        return drizzleState.messages.messages[0].text
    }





      return (
        <div className="news-ticker" style={{height:"86px",background:"#1f1c33",padding:"16px 0"}}>
            <Ticker mode="smooth" speed={speed}>
            {() => (
              <div style={floating}>
                <p style={style}>{getMessage()} </p>
                <p style={style}><span style={style2}>{getLink()}</span> </p>
              </div>
            )}
            </Ticker>
        </div>
      );
    }}
  </DrizzleContext.Consumer>
);
