import React, { useState, useEffect } from 'react'
import { DrizzleContext } from "drizzle-react";
import './style.scss';

import Table from '../../components/TableResults/Table';
import Pagination from '../../components/pagination/pagination';
//import { newContextComponents } from "drizzle-react-components";
//const { ContractData } = newContextComponents;
import ContractData from '../../components/ContractData/ContractData';
//import data



const Results = () => {
  const headerobj = [
      {name:"Entrant #"},
      {name:"Choice"},
      {name:"Player"}
  ]

  const [nextToValidate, setNextToValidate] = useState(0);
  const [nextValid, setNextValid] = useState(0);


  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);


  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);


      //paginate forward
  const paginateForward = () => {
//      setPages(pages => pages + 1)

    if(pages === currentPage) {return null}

    setCurrentPage(+currentPage + 1)
    setEnd(end+10)
    setStart(start+10)
//    setWaitlistMoveCount(+waitlistMoveCount + 10)
  }

  //paginate backward
  const paginateBackward = () => {
  //    console.log(pages)
      if(currentPage === 1) {return null}

      setCurrentPage(+currentPage - 1)

      if(start-10 > 0) {
        setEnd(end-10)
        setStart(start-10)
      }
      else {
        setEnd(11)
        setStart(1)
      }

  //    setWaitlistMoveCount(+waitlistMoveCount - 10)
  }

//  console.log(nextToValidate + ' ' + nextValid)

  useEffect(() => {}, []);


  return (
    <DrizzleContext.Consumer>
      {drizzleContext => {
        const { drizzle, drizzleState, initialized } = drizzleContext;

        const { web3 } = drizzle

        if (!initialized) {
          return "Loading...";
        }

        if (!drizzleState.drizzleStatus.initialized) {
          return "Loading...";
        }

        let _nextToValidate = drizzleState.contracts.Truel.nextToValidate
        let _nextValid = drizzleState.contracts.Truel.nextValid
    //    let waitlistMoveCount = 0;


        if(_nextToValidate['0x0'] !== undefined && start === 0) {

          const nextToValidate = +_nextToValidate['0x0'].value;
          setNextToValidate(nextToValidate)

          setCurrentPage((nextToValidate/10).toFixed(0))
          //start at page 1
          //setCurrentPage(1)

          setEnd(nextToValidate)
          setStart(nextToValidate-10)

        }

        //  console.log(nextValid)
        if(_nextValid['0x0'] !== undefined && nextValid === 0) {
  //        console.log(';;;;;;;;;;;;;;;;;;;')

          const nextValid = +_nextValid['0x0'].value;
          setNextValid(nextValid)
    //      console.log(nextValid)
          setPages((nextValid/10).toFixed(0))

        }



        const renderNextToValidate = data => {

        //  console.log(data)

          if(data !== nextToValidate) {
//            setWaitlistCount(data)
//            setPages((data/10).toFixed(0))
          }

          return(
            <>
            {data}
            </>
          )

        };

        const renderNextValid = data => {

          return(
            <>
            {nextValid}
            </>
          )

        };

        const renderNormal = () => {

          if(window.web3.currentProvider.isTrust) {
            return (
              <div className="results">
                  <div className="rounds">
                    <p>
                    This page is not supported in Trust Wallet.
                    </p>
                    <p>Coming Soon(tm)</p>
                  </div>
              </div>
            )
          }

          return (
            <div className="results">
                <div className="rounds">
                <p>Rounds
                {" "}
                <ContractData
                 drizzle={drizzle}
                 drizzleState={drizzleState}
                 contract="Truel"
                 method="nextToValidate"
                 render={renderNextToValidate}
               />
               /
               <ContractData
                drizzle={drizzle}
                drizzleState={drizzleState}
                contract="Truel"
                method="nextValid"
                render={renderNextValid}
              />
               </p>

                </div>
                <Table type="results"
                start={start}
                end={end}
                header={headerobj}/>

               <Pagination
                currentPage={currentPage}
                totalPages={pages}
                paginateFront={paginateForward}
                paginateBack={paginateBackward} />
            </div>
          )
        }


        return (
          renderNormal()
        );
      }}
    </DrizzleContext.Consumer>
  );
};

export default Results;
/*
            <Table type="waitlist"
            start={waitlistMoveCount}
            end={(waitlistMoveCount+10)}
            header={headerobj}/>
            */

/*



export default () => (

  <DrizzleContext.Consumer>

    {drizzleContext => {
      const { drizzle, drizzleState, initialized } = drizzleContext;

//      const [page,setPage] = useState(1);
//      const dataObj = data(1);


      //paginate forward
  //    const paginateForward = () => {
  //        setPage(prevPage => prevPage + 1)
  //    }

      //paginate backward
  //    const paginateBackward = () => {
  //        setPage(prevPage => prevPage - 1)
  //    }

      const headerobj = [
          {name:"Round #"},
          {name:"P1"},
          {name:"P2"},
          {name:"P3"}
      ]
      if (!initialized) {
        return "Loading...";
      }

      if (!drizzleState.drizzleStatus.initialized) {
        return "Loading...";
      }

      return (
        <div className="results">
           <div className="rounds">
           <p>Round
           {" "}
           <ContractData
            drizzle={drizzle}
            drizzleState={drizzleState}
            contract="Truel"
            method="nextToValidate"
          />
          /
          <ContractData
           drizzle={drizzle}
           drizzleState={drizzleState}
           contract="Truel"
           method="nextValid"
         />
          </p>

            </div>

           <Table type="results" header={headerobj} count="180"/>
           <Pagination
            //currentPage={dataObj.rounds.current_page}
            //totalRecords={dataObj.rounds.totalRecords}
            //paginateBack={paginateBackward}
            //paginateFront={paginateForward}
            />
        </div>
      );
    }}
  </DrizzleContext.Consumer>
);


*/
