import React from 'react';
import './style.scss';
import IconArrowBack from '../../assets/icons/icon-arrowback.svg';
import IconHTP from '../../assets/icons/icon-howtoplay.svg';
import { Link } from 'react-router-dom';

const RulesDetail = () => {
    return (
        <div className="rules-detail">
          <Nav header="How To Play" icon={IconHTP}/>
          <div className="text-body">

          <h4>Basic Game Play</h4>
          <p>
          3 players pick a direction to fire. This enters the waitlist & mints
          P3X via the contract.  50% of player minted P3X is staked in the
          round.  50% of player minted P3X is sent to players contract balance.
          Players who survive the round receive their staked P3X.  Players who
          shoot and kill other players during the round take the other players staked P3X.

          Entry fee can be recalibrated every 30 days via contract beacon which
          maintains static USD entry cost.
          </p>

          <ol>
          <li>Pick direction to fire [ left | miss | right ]</li>
          <li>Firing left or right has 10% backfire chance</li>
          <li>Firing enters waitlist & mints P3X</li>
          <li>50% can be withdrawn instantly</li>
          <li>50% stakes the entrants 3Duel round</li>
          <li>Players can truel vs themselves</li>
          <li>Create playernames <Link to="/name">here</Link></li>
          </ol>

          <h4>Game Theory</h4>
          <p>Truel game theory info {' '}<a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Truel">here.</a></p>
          <p>Reskin Launch article {' '}
            <a target="_blank" rel="noopener noreferrer" href="https://bit.ly/2B7labI">here.</a>
          </p>

          <p>Design behind Bonded Token Minting Gamification design can be found {' '}
            <a target="_blank" rel="noopener noreferrer" href="https://medium.com/@3duel/3duel-gamification-of-bonded-token-minting-2642ed9c50c2">here.</a>
          </p>


          <h4>P3X Design & Exchange</h4>
          <p>Developer was not creator of token used in game. Details of P3X Design {' '}
            <a target="_blank" rel="noopener noreferrer" href="https://www.powh3d.global/p3x/">here</a>
          </p>
          <p>Buy/Sell tokens without playing game {' '}
            <a target="_blank" rel="noopener noreferrer" href="https://wonnim.github.io/p3x-exchange/?mn=0xD11851c8Ba1dcEB72207aCB63fAa93253D57Cc00">here</a>
          </p>

          <h4>Expanded Scenarios</h4>
          <p>Different in game scenarios explained {' '}
          <Link to="/rules/scenario">here</Link>
          </p>

          <h4>Contact</h4>
          <ol>
          <li>
            <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:glottoadmin@protonmail.com">email</a>
          </li>
          <li><a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/megaballio/3duel">github</a>
          </li>
          <li><a
            target="_blank"
            rel="noopener noreferrer"
            href="t.me/threeduel">telegram</a>
          </li>

          <li><a
            target="_blank"
            rel="noopener noreferrer"
            href="https://discord.gg/3FqPvkW">discord</a>
          </li>
          </ol>


          <h4>Development</h4>
          <p><a
          target="_blank"
          rel="noopener noreferrer"
          href="https://etherscan.io/address/0xD11851c8Ba1dcEB72207aCB63fAa93253D57Cc00#code">3Duel source</a> made with {'<'}3.add(hate)</p>

          </div>
        </div>
    )
}

export default RulesDetail;

const Nav = ({header,icon}) => {
    return (
        <div className="detail-nav">
            <div className="item1">
            <Link to="/rules"><img src={IconArrowBack} alt="back"/></Link>
            <h1 className="header-title" style={{marginBottom:0}}>{header}</h1>
            </div>
            <div>
            <img src={icon} alt={`icon-${header}`}/>
            </div>
        </div>
    );
}
