import { put, takeEvery } from 'redux-saga/effects'

// actions
const ROUND_DELETE = 'TRUEL/ROUND_DELETE'
const ROUND_CREATE = 'TRUEL/ROUND_CREATE'

// reducers
const roundsReducer = (state = [], action) => {
 if (action.type === ROUND_CREATE) {
   // update your state
   console.log('rec')
//   console.log(action)

   return action.todos
 }

 return state
}

// fetch data from service using sagas
export function *fetchRound() {
//const todos = yield fetch('https://jsonplaceholder.typicode.com/todos').then(resp => response.json())
 //yield put({ type: ROUND_DISPLAY })
}

// Combine all your redux concerns

// app root saga
function *appRootSaga() {
 yield takeEvery(ROUND_CREATE, fetchRound)
}
