import React from 'react';
import './style.scss';
import { MobileView,BrowserView } from 'react-device-detect';
import { Route,useLocation } from 'react-router-dom'

import Header from '../../components/header/Header';
import BottomNav from '../../components/bottomNav/bottomNav';

//import content pages
import Rules from '../rules/Rules';
import Rewards from '../rewards/Rewards';
import RulesDetail from '../rulesDetail/RulesDetail';
import JackpotDetail from '../rulesDetail/JackpotDetail';

import WaitlistDetail from '../rulesDetail/WaitlistDetail';
import RoundDetail from '../rulesDetail/RoundDetail';
import FeeDetail from '../rulesDetail/FeeDetail';
import RewardDetail from '../rulesDetail/RewardDetail';
import ScenarioDetail from '../rulesDetail/ScenarioDetail';

import CreateName from '../createName/createName';

import Results from '../results/results';
import Waitlist from '../waitlist/waitlist';
//import Waitlist from '../originalWaitlist/waitlist';
import Play from '../play/play';
import Balance from '../balance/balance';
import News from '../../components/news/news';

import Particles from '../../components/headerParticle/headerParticle';

const Home = () => {
    const location = useLocation();
    const { pathname } = location;
    return (
        <>
        <MobileView viewClassName="home">
            {pathname !== '/' && <Header/> }
            {pathname === '/' && <Particles />}
            <div className="content">
              <Route exact path="/" component={Play}/>
              <Route exact path="/waitlist" component={Waitlist}/>
              <Route exact path="/results" component={Results}/>
              <Route exact path="/rewards" component={Rewards}/>
              <Route exact path="/rules" component={Rules}/>
              <Route exact path="/rules/details" component={RulesDetail}/>
              <Route exact path="/rules/jackpots" component={JackpotDetail}/>
              <Route exact path="/rules/waitlist" component={WaitlistDetail}/>
              <Route exact path="/rules/round" component={RoundDetail}/>
              <Route exact path="/rules/fee" component={FeeDetail}/>
              <Route exact path="/rules/reward" component={RewardDetail}/>
              <Route exact path="/rules/scenario" component={ScenarioDetail}/>
              <Route exact path="/name" component={CreateName}/>
              <Route exact path="/balance" component={Balance}/>
            </div>
            <News/>
            <BottomNav/>
        </MobileView>


        <BrowserView viewClassName="dHome">
          {pathname !== '/' && <Header/> }
          {pathname === '/' && <Particles />}

          <div className="dContent">
          <Route exact path="/" component={Play}/>
          <Route exact path="/waitlist" component={Waitlist}/>
          <Route exact path="/results" component={Results}/>
          <Route exact path="/rewards" component={Rewards}/>
          <Route exact path="/rules" component={Rules}/>
          <Route exact path="/rules/details" component={RulesDetail}/>
          <Route exact path="/rules/jackpots" component={JackpotDetail}/>
          <Route exact path="/rules/waitlist" component={WaitlistDetail}/>
          <Route exact path="/rules/round" component={RoundDetail}/>
          <Route exact path="/rules/fee" component={FeeDetail}/>
          <Route exact path="/rules/reward" component={RewardDetail}/>
          <Route exact path="/rules/scenario" component={ScenarioDetail}/>
          <Route exact path="/name" component={CreateName}/>
          <Route exact path="/balance" component={Balance}/>
          </div>
          <News/>
          <BottomNav/>
        </BrowserView>

       </>
    )
}

export default Home;

/*   return (
      <>
      <MobileView viewClassName="home">
          {pathname !== '/' && <Header/> }

          <div className="content">
          <Route exact path="/" component={Play}/>
          <Route exact path="/waitlist" component={Waitlist}/>
          <Route exact path="/results" component={Results}/>
          <Route exact path="/rules" component={Rules}/>
          <Route exact path="/rules/details" component={RulesDetail}/>
          <Route exact path="/balance" component={Balance}/>
          </div>
          {pathname === '/' && <News/>}
          <BottomNav/>
      </MobileView>


      <BrowserView viewClassName="home">
       {pathname !== '/' && <Header/> }

      <div className="content">
      <Route exact path="/" component={Play}/>
      <Route exact path="/waitlist" component={Waitlist}/>
      <Route exact path="/results" component={Results}/>
      <Route exact path="/rules" component={Rules}/>
      <Route exact path="/rules/details" component={RulesDetail}/>
      <Route exact path="/balance" component={Balance}/>
      </div>
      {pathname === '/' && <News/>}
      <BottomNav/>
      </BrowserView>

     </>
  )*/
