import React from 'react';
import './style.scss';
//import Icons
import IconArrowBack from '../../assets/icons/icon-arrowback.svg';
import IconArrowFront from '../../assets/icons/icon-arrowfront.svg';

const pagination = ({currentPage,totalPages,paginateBack,paginateFront}) => {
    return (
        <div className="pagination">
            <img className="arrow-back" onClick={currentPage === 0? null :paginateBack} src={IconArrowBack} alt="back"/>
            <p className="total-records"><span className="current-page">{currentPage}</span>/{totalPages}</p>
            <img className="arrow-front" onClick={paginateFront} src={IconArrowFront} alt="forward"/>
        </div>
    )
}

export default pagination;
/*<p className="total-records"><span className="current-page">{currentPage}</span>/{totalRecords}</p> */
